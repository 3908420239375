import { ICbVwPawOrderReview } from '@/shared/model/cb-vw-paw-order-review.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import axios from 'axios';

const baseApiUrl = 'services/cbcorems/api/cb-vw-paw-order-review-4-crms/by-user-id';
export default class PCbVwPawOrderReviewService {
  public retrieveByUserId(param?: any,id?: any): Promise<ICbVwPawOrderReview> {
    return new Promise<ICbVwPawOrderReview>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}?${buildPaginationQueryOpts(param)}`)
        .then(res => {
          resolve(<ICbVwPawOrderReview>res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
