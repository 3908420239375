export interface ICmVwUser {
  id?: string;
  email?: string | null;
  fullName?: string | null;
  nickName?: string | null;
  address?: string | null;
  dateOfBirth?: Date | null;
  phone?: string | null;
  photoId?: number | null;
  createdDate?: Date | null;
  createdBy?: string | null;
  lastModifiedDate?: Date | null;
  lastModifiedBy?: string | null;
  isAtivated?: boolean | null;
  authorities?: string | null;
  downloadLink?: string | null;
  langKey?: string | null;
  // transient
  roleDepartement?: any | null;

  //
  login?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  imageUrl?: null;
  isActivated?:boolean| false;

  isAgent?: boolean | null;
}

export class CmVwUser implements ICmVwUser {
  constructor(
    public id?: string,
    public email?: string | null,
    public fullName?: string | null,
    public nickName?: string | null,
    public address?: string | null,
    public dateOfBirth?: Date | null,
    public phone?: string | null,
    public photoId?: number | null,
    public createdDate?: Date | null,
    public createdBy?: string | null,
    public lastModifiedDate?: Date | null,
    public lastModifiedBy?: string | null,
    public isActivated?: boolean | null,
    public authorities?: string | null,
    public donwloadLink?: string | null,
    public login?: string | null,
    public firstName?: string | null,
    public lastName?: string | null,
    public imageUrl?: null,
    public isAgent?: false,
    public langKey?: string | null,
    // transient
    public roleDepartement?: any | null
  ) {
    this.isActivated = this.isActivated ?? false;
    this.isAgent = this.isAgent ?? false;
  }
}
