import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IChtMessage } from '@/shared/model/cht-message.model';
import { ChtConversation, IChtConversation } from '@/shared/model/cht-conversation.model';
import { ChtAggConversationMember, IChtAggConversationMember } from '@/shared/model/cht-agg-conversation-member.model';
import { ChtVwConversation, IChtVwConversation } from '@/shared/model/cht-vw-conversation.model';
import { ChtVwConversationCount } from '@/shared/model/cht-vw-conversation-count.model';
import { ChtConversationType } from '@/shared/model/enumerations/cht-conversation-type.model';
import { IChtFile } from '@/shared/model/cht-file.model';
import { IChtVwMessage } from '@/shared/model/cht-vw-message.model';
import { IChtStory } from '@/shared/model/cht-story.model';

import { CHT_CBAPP_SELECTED } from '@/constants';
import { IChtReaction } from '@/shared/model/cht-reaction.model';
// import { IChtLiveAgentQuestion } from '@/shared/model/cht-live-agent-question.model';
// import { IChtLiveAgentQuestion } from '@/shared/model/cht-live-agent-question.model';

const baseApiChtUrl = 'services/btichat/api';
const baseApiFileUrl = 'services/btichat/api/cht-files';
const baseApiReactionUrl = 'services/btichat/api/cht-reactions';
const userApiUrl = 'services/cbuserms/api/cb-vw-user-cores';

export default class PChtService {
  public createConversation(userId: string, isLiveChat: boolean, isAgent: boolean): Promise<IChtConversation> {
    var conversation: IChtConversation = new ChtConversation();
    var aggConversationMember: IChtAggConversationMember = new ChtAggConversationMember();
    conversation.app = CHT_CBAPP_SELECTED;
    conversation.conversationType = ChtConversationType.PERSONAL;
    conversation.isLiveChat = true;
    conversation.isAgent = isAgent;
    aggConversationMember.userId = userId;
    if (!conversation.chtAggConversationMembers) conversation.chtAggConversationMembers = [];
    conversation.chtAggConversationMembers.push(aggConversationMember);

    return new Promise<IChtConversation>((resolve, reject) => {
      axios.post(`${baseApiChtUrl}/cht-conversations/create-personal/by-id`, conversation).then(res => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject(false);
        }
      });
      // .catch(err => {
      //   reject(err);
      // });
    });
  }
  public frameCreateConversation(userId: string): Promise<IChtConversation> {
    var conversation: IChtConversation = new ChtConversation();
    var aggConversationMember: IChtAggConversationMember = new ChtAggConversationMember();
    // var aggConversationMember: IChtAggConversationMember = new ChtAggConversationMember();
    conversation.app = CHT_CBAPP_SELECTED;
    conversation.conversationType = ChtConversationType.PERSONAL;

    aggConversationMember.userId = userId;
    if (!conversation.chtAggConversationMembers) conversation.chtAggConversationMembers = [];
    conversation.chtAggConversationMembers.push(aggConversationMember);

    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/create-personal/by-id`, conversation)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateConversation(entity: IChtConversation): Promise<IChtConversation> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .put(`${baseApiChtUrl}/cht-conversations/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createConversationGroup(entity: IChtConversation): Promise<IChtConversation> {
    entity.app = CHT_CBAPP_SELECTED;
    if (!entity.conversationType) entity.conversationType = ChtConversationType.GROUP;
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/create-group`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public findChtMessageById(id: number): Promise<IChtVwMessage> {
  //   return new Promise<IChtVwMessage>((resolve, reject) => {
  //     axios
  //       .get(`${baseApiChtUrl}/cht-vw-messages/${id}`)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public retrieveMessageByConversationId(conversationId: number, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-login/${conversationId}` + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageByLogin(params?: any, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-login` + `?${params}${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageById(messageId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-id/${messageId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageById4Crm(messageId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-id/4-crm/${messageId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createChtFile(entity: IChtFile): Promise<IChtFile> {
    return new Promise<IChtFile>((resolve, reject) => {
      axios
        .post(`${baseApiFileUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public createMessage(entity: IChtMessage): Promise<IChtMessage> {
    entity.app = CHT_CBAPP_SELECTED;
    return new Promise<IChtMessage>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-messages`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createMessage4Crm(entity: IChtMessage): Promise<IChtMessage> {
    entity.app = CHT_CBAPP_SELECTED;
    return new Promise<IChtMessage>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-messages/4-crm`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createMessageForward(entity: IChtMessage): Promise<IChtMessage> {
    entity.app = CHT_CBAPP_SELECTED;
    return new Promise<IChtMessage>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-messages/forward`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createChatStory(entity: IChtStory): Promise<IChtStory> {
    entity.app = CHT_CBAPP_SELECTED;
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-stories`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveConversationCountByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversation-counts/by-login/' + CHT_CBAPP_SELECTED)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public findConversationCount(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/count', { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findChtVwConversationCount(conversationId): Promise<ChtVwConversationCount> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversation-counts/one/' + conversationId + '/' + CHT_CBAPP_SELECTED)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findChtVwConversation(conversationId): Promise<ChtVwConversation> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/one/' + conversationId + '/' + CHT_CBAPP_SELECTED)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public frameFindChtVwConversation(conversationId): Promise<ChtVwConversation> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/one/' + conversationId + '/' + CHT_CBAPP_SELECTED)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public setLatestViewConversation(conversation: IChtConversation): Promise<ChtAggConversationMember> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/set-latest-view`, conversation)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveContact(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-agg-contacts', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveStory(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-stories' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveConversationLiveAgentNoLogin(liveAgentKey?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/live-agent/no-login/' + CHT_CBAPP_SELECTED + '/' + liveAgentKey)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageBymessageIdAndKey(messageId: number, key: String): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/live-agent/no-login/by-id/${messageId}/${key}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageByConversationIdAndKey(conversationId: number, key: String, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl + `/cht-vw-messages/live-agent/no-login/${conversationId}/${key}` + `?${buildPaginationQueryOpts(paginationQuery)}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createMessageLiveAgentNoLogin(key, entity: IChtMessage): Promise<IChtMessage> {
    entity.app = CHT_CBAPP_SELECTED;
    return new Promise<IChtMessage>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-messages/live-agent/no-login/${key}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public retrieveConversationByLogin(params?: any): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     axios
  //       .get(baseApiChtUrl + '/cht-vw-conversations/by-login/' + CHT_CBAPP_SELECTED, { params })
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public retrieveConversation(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl +
            '/cht-vw-conversations' +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveConversationByLogin(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl +
            '/cht-vw-conversations/by-login/' +
            CHT_CBAPP_SELECTED +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveTotalCountUnread(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-conversation-counts/by-app/${CHT_CBAPP_SELECTED}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteReaction(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiReactionUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createReaction(entity: IChtReaction): Promise<IChtReaction> {
    return new Promise<IChtReaction>((resolve, reject) => {
      axios
        .post(`${baseApiReactionUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public isTokenValid(token: String): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/is-token-valid/${token}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageStars(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/star/by-login` + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveVwUser(params?: any): Promise<any> {
    if (params.realUserIds) {
      const uniqueArray = params.realUserIds.replace(/\s/g, '').split('||').filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      params['id.in'] = uniqueArray;
      delete params.realUserIds;
    }
    if (!params.page) {
      params['page'] = 0;
    }
    if (!params.size) {
      params['size'] = 500;
    }
    console.log('params', params);
    return new Promise<any>((resolve, reject) => {
      axios
        //  .get(userApiUrl + '?id.in=08eca304-f4b4-48ef-b8b4-745f826953cf&id.in=13bde4c9-253c-42b7-aa85-7a5f434d958a&page=0&size=20', { params })
        .get(userApiUrl, { params })

        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public pinned(roomId, negate): Promise<ChtAggConversationMember> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/pinned/${roomId}/${negate}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public createConversationLiveAgentNoLogin(chtLiveAgentQuestion: IChtLiveAgentQuestion): Promise<IChtConversation> {
  //   return new Promise<IChtConversation>((resolve, reject) => {
  //     axios
  //       .post(`${baseApiChtUrl}/cht-conversations/live-agent/create-personal/no-login`, chtLiveAgentQuestion)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public retrieveConversationById(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations' + `/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageByMemberCrmEmailConversationId(conversationId: number, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-member-crm-email/${conversationId}` + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
