import axios, { AxiosResponse } from "axios";
import { ICmGlobalTargets } from "@/shared/model/cm-global-targets.model";
import buildPaginationQueryOpts from "@/shared/sort/sorts";
import { ICmVwGlobalTarget } from "@/shared/model/cm-vw-global-targets.model";

const baseUrl = 'services/crmcorems/api/cm-global-targets';
const baseUrl2 = 'services/crmcorems/api/cm-vw-global-targets';

export default class PCmGlobalTargets {
  public retrieve(paginationQuery): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      axios.get(`${baseUrl2}?${buildPaginationQueryOpts(paginationQuery)}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }

  public find(id: number): Promise<ICmGlobalTargets> {
    return new Promise((resolve, reject) => {
      axios.get(`${baseUrl}/${id}`).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }

  public create(cmGlobalTargets: ICmGlobalTargets): Promise<ICmGlobalTargets> {
    return new Promise((resolve, reject) => {
      axios.post(baseUrl, cmGlobalTargets).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }

  public update(cmGlobalTargets: ICmGlobalTargets): Promise<ICmGlobalTargets> {
    return new Promise((resolve, reject) => {
      axios.patch(`${baseUrl}/${cmGlobalTargets.id}`, cmGlobalTargets).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseUrl}/${id}`).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }
}
