import GlobalService from '@/modules/services/global.service';
import axios from 'axios';

const TIMEOUT = 1000000;
const onRequestSuccess = config => {
  //set 2 ways login
  const token = localStorage.getItem('crm-jhi-authenticationToken') || sessionStorage.getItem('crm-jhi-authenticationToken');

  if (
    token &&
    'api/authenticate/refresh' != config.url &&
    !config.url.includes('translation.googleapis.com') &&
    !config.url.includes('storage.googleapis.com')
  ) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    if (config.url.includes('api/logout')) {
      config.headers.IdToken = localStorage.getItem('crm-jhi-id-token');
    }
  }

  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;
  return config;
};

let globalService: GlobalService; //dont set null
let isRefreshing = false;
const subscribers = [];
function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}
function onRrefreshed(access_token) {
  subscribers.map(cb => cb(access_token));
}

const setupAxiosInterceptors = (onUnauthenticated, onServerError, globalServiceP: GlobalService) => {
  globalService = globalServiceP;

  const onResponseError = async err => {
    const originalRequest = err.config;
    const url = err.response?.config?.url;
    if (globalService.isHasRefreshToken() && err.response.status === 401 && !originalRequest._retry && 'api/authenticate/refresh' != url) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        return await new Promise<any>((resolve, reject) => {
          //must set return to callback
          globalService
            .checkRefreshToken()
            .then(res => {
              isRefreshing = false;
              onRrefreshed(res);
              const access_token = res;

              //call at first  api
              originalRequest.headers.Authorization = `Bearer ${access_token}`;
              resolve(axios(originalRequest));
            })
            .catch(err => {
              isRefreshing = false;
              globalService.handleExpiredRefreshToken();
              reject(err);
            });
        });
      }
      //call at second api , because when refresh tokaen call, ti is not trigger
      return new Promise(resolve => {
        subscribeTokenRefresh(access_token => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    isRefreshing = false;
    const status = err.status || err.response.status;
    if (status === 403 || status === 401) {
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }
    return Promise.reject(err);
  };
  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(res => res, onResponseError);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };
