import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import Vue from 'vue';
import dayjs from 'dayjs';

export function initFiltersLov() {
  Vue.filter('CONTENT_IMAGE', (cdnUrl, CONTENT_IMAGE, key) => {
    const lov = CONTENT_IMAGE.length ? CONTENT_IMAGE.find(l => l.key === key) : null;
    if (!!lov) return cdnUrl + '/CbAttachmentNoAuth/' + lov.value;
    return null;
  });
}
