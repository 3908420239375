import Vuex from 'vuex';
import VueI18n, { DateTimeFormats } from 'vue-i18n';
import JhiFormatter from './formatter';
import VuexPersist from 'vuex-persist';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons/faArrowsRotate';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons/faPowerOff';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons/faEllipsisVertical';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply';
import { faReplyAll } from '@fortawesome/free-solid-svg-icons/faReplyAll';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';

import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';

import * as filters from '@/shared/date/filters';
import * as filtersChat from '@/shared/chat/filters';
import * as filtersGeneral from '@/shared/general/filters';
import * as filtersLov from '@/shared/lov/filters';
import * as securityFilters from '@/shared/security/security-filters';

import { accountStore } from '@/shared/config/store/account-store';
import { translationStore } from '@/shared/config/store/translation-store';

import { chartsStore } from './store/chart-store';
import { crmStore } from './store/crm-store';
import { lovStore } from './store/lov-store';
import { chatStore } from './store/chat-store';
import { contentTextStore } from './store/content-text-store';


const dateTimeFormats: DateTimeFormats = {
  in: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  // jhipster-needle-i18n-language-date-time-format - JHipster will add/remove format options in this object
};

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vuelidate);
  vue.use(Vue2Filters);
  filters.initFilters();
  filtersChat.initFiltersChat();
  filtersLov.initFiltersLov();
  filtersGeneral.initFilterGeneral();
  securityFilters.initSecurityFilters();
}

export function initFortAwesome(vue) {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBars,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimes,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faBell,
    faCaretDown,
    faEnvelope,
    faArrowsRotate,
    faPlus,
    faCircle,
    faAngleDown,
    faAngleUp,
    faAngleLeft,
    faAngleRight,
    faStar,
    faPowerOff,
    faEllipsisVertical,
    faArrowDown,
    faPaperclip,
    faReply,
    faReplyAll,
    faShare,
    faCheck,
    faRedo,
    faCalendar,
    faSpinner,
    faCaretLeft,
    faCaretRight
  );
}

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    dateTimeFormats,
    silentTranslationWarn: true,
    formatter: new JhiFormatter(),
  });
}
export function initVueXStore(vue) {
  const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
  });
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      accountStore,
      translationStore,
      chartsStore,
      crmStore,
      lovStore,
      contentTextStore,
      chatStore,
    },
    plugins: [vuexLocalStorage.plugin],
  });
}
