import PCbLovService from './cb-lov.service';
import { Inject, Vue } from 'vue-property-decorator';
import store from '@/store'; // Import the Vue store
import { initVueXStore } from '@/shared/config/config';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-4-crms';
const baseApiUrlCore = 'services/cbuserms/api/cb-vw-user-4-crm-cores';
const store = initVueXStore(Vue);

export default class ContentService extends Vue {
  public pCbLovService = () => new PCbLovService();
  public $store = store;
  public initRetrieveCbLovs(lovType) {
    if (this.$store.getters[lovType].length == 0) {
      this.retrieveCbLovs(lovType);
    }
  }

  public retrieveCbLovs(lovType): void {
    const paginationQuery = {
      'name.equals': lovType,
      page: 0,
      size: 99999,
      'isActive.equals': 'true',
    };
    this.pCbLovService()
      .retrieve(paginationQuery)
      .then(
        res => {
          if (
            [
              'CONTENT_BANNER_BUYER',
              'MAJOR',
              'CONTENT_BANNER_PARTNER',
              'CONTENT_IMAGE',
              'TIME_ZONE',
              'COUNTRY',
              'PROVINCE',
              'CITY',
              'LANGUAGE_CODE',
              'LANGUAGE_LEVEL',
              'LANGUAGE',
              'JOB',
            ].includes(lovType)
          ) {
            this.$store.commit(lovType, res.data);
          }
        },
        err => {}
      )
      .finally(() => {});
  }
}
