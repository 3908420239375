import { IChtAttachmentOne } from '@/shared/model/cht-attachment-one.model';
import { IChtMessage } from '@/shared/model/cht-message.model';
import { IChtAggConversationMember } from '@/shared/model/cht-agg-conversation-member.model';

import { ChtConversationType } from '@/shared/model/enumerations/cht-conversation-type.model';
import { App } from '@/shared/model/enumerations/app.model';
export interface IChtConversation {
  id?: number;
  name?: string | null;
  description?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  conversationType?: ChtConversationType | null;
  secretConversationIdRef?: number | null;
  isActive?: boolean | null;
  app?: App | null;
  avatar?: IChtAttachmentOne | null;
  chtMessages?: IChtMessage[] | null;
  chtAggConversationMembers?: IChtAggConversationMember[] | null;
  isLiveChat?: boolean | null;
  isAgent?: Boolean | null;
}

export class ChtConversation implements IChtConversation {
  constructor(
    public id?: number,
    public name?: string | null,
    public description?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public conversationType?: ChtConversationType | null,
    public secretConversationIdRef?: number | null,
    public isActive?: boolean | null,
    public app?: App | null,
    public avatar?: IChtAttachmentOne | null,
    public chtMessages?: IChtMessage[] | null,
    public chtAggConversationMembers?: IChtAggConversationMember[] | null,
    public isLiveChat?: boolean | null,
    public isAgent?: Boolean | null
  ) {
    this.isActive = this.isActive ?? false;
  }
}
