import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICbVwUserReview } from '@/shared/model/cb-vw-user-review.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-reviews';

export default class PCbVwUserReviewService {
  public find(id: number): Promise<ICbVwUserReview> {
    return new Promise<ICbVwUserReview>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findById(userId: string): Promise<ICbVwUserReview> {
    return new Promise<ICbVwUserReview>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-user-id/${userId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveByParam(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbVwUserReview): Promise<ICbVwUserReview> {
    return new Promise<ICbVwUserReview>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbVwUserReview): Promise<ICbVwUserReview> {
    return new Promise<ICbVwUserReview>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbVwUserReview): Promise<ICbVwUserReview> {
    return new Promise<ICbVwUserReview>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
