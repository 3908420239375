import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ICmSopInputedAgg } from '@/shared/model/cm-sop-inputed-agg.model';
import { ICmAttachmentAuth } from '@/shared/model/cm-attachment-auth.model';
import { ICmTkt } from '@/shared/model/cm-tkt.model';
import { ICmTktStatusResolution } from '@/shared/model/cm-tkt-status-resolution.model';
import { ICmTktEscalate } from '@/shared/model/cm-tkt-escalate.model';
import { ICmVwEmailTkt } from '@/shared/model/cm-vw-email-tkt.model';
import { ICmEmail } from '@/shared/model/cm-email.model';
import { ICmVwTkt } from '@/shared/model/cm-vw-tkt.model';
import { ICmTktShareTo } from '@/shared/model/cm-tkt-share-to.model';
import { ICmEmailAttachment } from '@/shared/model/cm-email-attachment.model';
import { ICmTktResolution } from '@/shared/model/cm-tkt-resolution.model';
import { ICmTktTag } from '@/shared/model/cm-tkt-tag.model';
import { ICmTextAuth } from '@/shared/model/cm-text-auth.model';
import { ICmTimeLine } from '@/shared/model/cm-time-line.model';
import { CmTktChannel } from '@/shared/model/enumerations/cm-tkt-channel.model';
import { ICmTktPic } from '@/shared/model/cm-tkt-pic.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { ICbVwBankAccount } from '@/shared/model/cb-vw-bank-account.model';

const baseApiUrl = 'services/crmcorems/api';
const baseApiUrl2 = 'services/crmemailms/api';
const baseApiUrl3 = 'api/users';
export default class PCrmMainService {
  public retrieveTicket(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-tkts' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public download(downloadLink: string, type: string, callBack: Function): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${downloadLink}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(signUrlRes => {
          let base64 = null;
          const blob = new Blob([signUrlRes.data], { type: signUrlRes.headers['content-type'] });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            base64 = reader.result;
            resolve(base64);
          };
        })
        .catch(signUrlErr => {
          reject(false);
        });
    });
  }

  public retrieveSender(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-tkt-senders' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveSops(channel): Promise<any> {
    return new Promise<any>((resolve, rejects) => {
      axios
        .get(baseApiUrl + `/cm-vw-sops?tktChannel.equals=${channel}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          rejects(err);
        });
    });
  }

  public createSopInputedAgg(entity: ICmSopInputedAgg): Promise<ICmSopInputedAgg> {
    return new Promise<ICmSopInputedAgg>((resolve, rejects) => {
      axios
        .post(baseApiUrl + '/cm-sop-inputed-aggs', entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          rejects(err);
        });
    });
  }

  public findOneAttachmentAuth(id: number): Promise<ICmAttachmentAuth> {
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-attachment-auths/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public findOneAttachmentAuthEmail(id: number): Promise<ICmAttachmentAuth> {
    // alert(baseApiUrl2);
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cm-attachment-auths/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveVwEmailTkt(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl2 + '/cm-vw-email-tkts' + `?${buildPaginationQueryOpts(paginationQuery)}`)

        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findOneVwEmailTkt(id: number): Promise<ICmVwEmailTkt> {
    return new Promise<ICmVwEmailTkt>((resolve, reject) => {
      axios
        .get(baseApiUrl2 + '/cm-vw-email-tkts/' + id)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findCmTkt(id: number): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-tkts/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateCmTkt(entity: ICmTkt): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/cm-tkts/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createStatusResolution(entity: ICmTktStatusResolution): Promise<ICmTktStatusResolution> {
    return new Promise<ICmTktStatusResolution>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-status-resolutions`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createStatusResolutionNoLogin(entity: ICmTktStatusResolution): Promise<ICmTktStatusResolution> {
    return new Promise<ICmTktStatusResolution>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-status-resolutions/live-chat/no-login/v2`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createEscalateTicket(entity: ICmTktEscalate): Promise<ICmTktEscalate> {
    return new Promise<ICmTktEscalate>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-escalates`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createEmail(entity: ICmEmail): Promise<ICmEmail> {
    return new Promise<ICmEmail>((resolve, reject) => {
      axios
        .post(`${baseApiUrl2}/cm-emails`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createEmailByLogin(entity: ICmEmail): Promise<ICmEmail> {
    return new Promise<ICmEmail>((resolve, reject) => {
      axios
        .post(`${baseApiUrl2}/cm-emails/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCmVwEmailByTicket(ticketId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cm-vw-email-tkts?externalId.equals=${ticketId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievecmVwTkt(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-vw-tkts' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrievecmVwTktByParams(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-vw-tkts', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievecmVwTktClosedIgnore(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-vw-tkts' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service for find one cm-vw-tkt with od
   * @param id
   * @returns
   */
  public findCmVwTkt(id: number): Promise<ICmVwTkt> {
    return new Promise<ICmVwTkt>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-vw-tkts/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to retrieve all users
   * @returns
   */
  public retrieveUsers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl3)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to create Tkt share to another agent
   * @param entity
   * @returns
   */
  public createTktShareto(entity: ICmTktShareTo): Promise<ICmTktShareTo> {
    return new Promise<ICmTktShareTo>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-share-tos`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to save email attachment
   * @param entity
   * @returns
   */
  public createCmEmailAtt(entity: ICmEmailAttachment, callBack: Function, callbackReject?: Function): Promise<ICmEmailAttachment> {
    let tmpBlob = null;
    if (entity.cmAttachmentAuth) {
      entity.cmAttachmentAuth.att = '';
      tmpBlob = entity.cmAttachmentAuth.blob;
      delete entity.cmAttachmentAuth.blob;
    }
    return new Promise<ICmEmailAttachment>((resolve, reject) => {
      axios
        .post(`${baseApiUrl2}/cm-email-attachments`, entity)
        .then(res => {
          const result: ICmEmailAttachment = res.data;
          resolve(this.upload(result, result.uploadLink, entity.cmAttachmentAuth.attContentType, tmpBlob, callBack, callbackReject));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteCmEmailAtt(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl2}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private upload(
    result: ICmEmailAttachment,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function,
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(callbackReject);
        });
    });
  }
  // public createCmEmailAtt(entity: ICmEmailAttachment): Promise<ICmEmailAttachment> {
  //   return new Promise<ICmEmailAttachment>((resolve, reject) => {
  //     axios
  //       .post(`${baseApiUrl2}/cm-email-attachments`, entity)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  /**
   * Service for download attachment Ticket
   * @param id
   * @returns
   */
  public downloadAttachment(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios({
        method: 'get',
        url: `${baseApiUrl}/cm-attachment-auths/${id}/download`,
        responseType: 'arraybuffer',
      })
        .then(res => {
          let headerLine = res.headers['content-disposition'];
          let startFileNameIndex = headerLine.indexOf('"') + 1;
          let endFileNameIndex = headerLine.lastIndexOf('"');
          let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

          var blob = new Blob([res.data], { type: res.headers['content-type'] });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /** */
  public retrievecmVwTktByTicketId(ticketId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-vw-tkts?ticketId.equals=${ticketId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Siervice to find one Attachment by id params
   * @param id
   * @returns
   */
  public retrieveOnecmAttachment(id: number): Promise<ICmAttachmentAuth> {
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-attachment-auths/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to create new cm-tkt-resolution
   * @param entity
   * @returns
   */
  public createNewCmTktResolution(entity: ICmTktResolution): Promise<ICmTktResolution> {
    return new Promise<ICmTktResolution>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-resolutions`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Servie to
   * @param entity
   * @returns
   */
  public createTag(entity: ICmTktTag): Promise<ICmTktTag> {
    return new Promise<ICmTktTag>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-tags`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateTag(entity: ICmTktTag): Promise<ICmTktTag> {
    return new Promise<ICmTktTag>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/cm-tkt-tags/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Sefvice to retrievecm-tkt-resolutions with pagination query
   * @param paginationQuery
   * @returns Promise<any>
   */
  public retrieveCmTktResolutions(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-tkt-resolutions' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to retrieve cm-tkt-tag wich match with ticket id
   * @param ticketId
   * @returns <any> (cm-tkt-tag) sesuai ticket id
   */
  public retrieveCmTktTagsByTicketId(cmTktId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cm-tkt-tags' + `?cmTktId.equals=${cmTktId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to delete tag
   * @param id
   * @returns
   */
  public deleteTag(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/cm-tkt-tags/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to find text auth by id
   * @param id
   * @returns  cmTextAuth
   */
  public findCmTextAuthById(id: number): Promise<ICmTextAuth> {
    return new Promise<ICmTextAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-text-auths/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to create Timeline
   * @param entity
   * @returns
   */
  public createTimeLineTkt(entity: ICmTimeLine): Promise<ICmTimeLine> {
    return new Promise<ICmTimeLine>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-time-lines`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to retrieve cm-time-lines by cmTktId
   * @param cmTktId
   * @returns
   */
  public retrieveCmTimelineByTktId(cmTktId: number, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-time-lines?${buildPaginationQueryOpts(paginationQuery)}&cmTktId.equals=${cmTktId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCmTktByChannelAndIsAccepted(channel: CmTktChannel, isAccepted: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-tkts?channel.equals=${channel}&isAccepted.equals=${isAccepted}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCmTktChanelLiveChatAndNotAccpeted(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-tkts?channel.equals=LIVE_CHAT&isAccepted.notEquals=true`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to partial update cm-tkt
   * @param entity
   * @returns
   */
  public partialUpdateCmtkt(entity: ICmTkt): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/cm-tkts/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Service to retrive cmVwUser by authorities
   * @param authorities
   * @returns
   */
  public retrieveCmVwUserByAuthorities(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-vw-users`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCmTktChatAccepted(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-vw-tkt-chat-accepteds?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createCmTktPic(entity: ICmTktPic): Promise<ICmTktPic> {
    return new Promise<ICmTktPic>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cm-tkt-pics`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public cmVwTktCountUnread(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-vw-tkts/count?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public cmVwTktCount(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-vw-tkts/count?`, { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public cmVwTktCountInbox(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          `${baseApiUrl}/cm-vw-tkts/count?channel.notEquals=LIVE_CHAT&resolutionStatus.notEquals=CLOSED&${buildPaginationQueryOpts(
            paginationQuery,
          )}`,
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public cmVwTktEmailCount(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cm-vw-email-tkts/count?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCmSopInputedAggs(cmTktId: number, externalId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cm-sop-inputed-aggs?externalId.equals=${externalId}&cmTktId.equals=${cmTktId}&size=100`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
