import axios from 'axios';
import { ICbVwBankAccount } from '@/shared/model/cb-vw-bank-account.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-bank-accounts';

export default class PCbVwBankAccountService {
  public retrieves(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/crm', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
