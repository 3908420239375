import { Subscription } from 'rxjs';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import SocketService from './admin/socket/socket.service';

import { ISocketMessage } from './shared/model/socket-message.model';

@Component({
  components: {},
})
export default class AppSocket extends Vue {
  @Inject('socketService') private socketService: () => SocketService;

  private subscriptionNotif?: Subscription;
  private subscriptionConversationBasedOnMember?: Subscription;
  private subscriptionConversationId?: Subscription;
  private subscribeUserOnline?: Subscription;
  private subscribeUserOnlineCb?: Subscription;
  private subscriptionLiveAgentConversationId?: Subscription;
  private subscribeNotifInbox?: Subscription;
  private subscribeNotifCb?: Subscription;
  private subscribeConversationLastSeen?: Subscription;

  public created() {
    this.initConnectSocketAndWatch();

    this.$root.$off('socket::subscribeConversationId');
    this.$root.$on('socket::subscribeConversationId', (conversationId: number) => {
      this.subscriptionConversationId = this.socketService().subscribeConversationId((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessage', msg.conversationId, msg.messageId);
      }, conversationId);
    });

    this.$root.$off('socket::subscribeConversationLastSeen');
    this.$root.$on('socket::subscribeConversationLastSeen', (conversationId: number) => {
      this.subscribeConversationLastSeen = this.socketService().subscribeLastSeen((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveLastSeenMessage', conversationId, msg);
      }, conversationId);
    });

    this.$root.$off('socket::unsubscribeConversationId');
    this.$root.$on('socket::unsubscribeConversationId', () => {
      if (this.subscriptionConversationId) {
        this.subscriptionConversationId.unsubscribe();
        this.subscriptionConversationId = undefined;
      }
    });

    this.$root.$off('socket::initSocketLiveAgent');
    this.$root.$on('socket::initSocketLiveAgent', () => {
      this.initSocketLiveAgent();
    });

    this.$root.$off('socket::unsubscribeLiveAgentConversationId');
    this.$root.$on('socket::unsubscribeLiveAgentConversationId', () => {
      this.unsubscribeLiveAgent();
    });

    this.$root.$off('socket::subscribeLiveAgentConversationId');
    this.$root.$on('socket::subscribeLiveAgentConversationId', (conversationId: number) => {

    });
  }


  public initConnectSocketAndWatch() {
    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated) {
          this.initSocket();
        } else {
          this.unsubscribe();
        }
      }
    );

    if (this.$store.getters.account && this.$store.getters.account.id) {
      this.initSocket();
    }
  }
  public initSocket(): void {
    this.socketService().isSessionLive();
    this.unsubscribe();
    this.socketService().connectCustome();
    this.subscriptionNotif = this.socketService().subscribeNotif((msg: ISocketMessage) => {
      const h = this.$createElement;
      const htmlToVnode = h('p', { domProps: { innerHTML: msg.body } });

      (<any>this.$root).$bvToast.toast(msg.title ? msg.title : htmlToVnode, {
        toaster: 'b-toaster-top-right',
        title: msg.title,
        variant: 'info',
        solid: true,
        autoHideDelay: 5000,
      });

      (<any>this.$root).$emit('onNotifRefreshListNotif', msg);
    });

    this.subscribeNotifInbox = this.socketService().subscribeInbox((msg: ISocketMessage) => {

      const h = this.$createElement;
      const htmlToVnode = h('p', { domProps: { innerHTML: msg.body } });

      (<any>this.$root).$bvToast.toast(msg.title ? msg.title : htmlToVnode + 'CRM', {
        toaster: 'b-toaster-top-right',
        title: msg.title,
        variant: 'info',
        solid: true,
        autoHideDelay: 5000,
      });
      (<any>this.$root).$emit('onNotifRefreshList', msg);
    });


    this.subscribeNotifCb = this.socketService().subscribNotifCb((msg: ISocketMessage) => {

      (<any>this.$root).$emit('onNotifRefreshList', msg);
    });

    this.subscriptionConversationBasedOnMember = this.socketService().subscribeConversationBasedOnMember((msg: ISocketMessage) => {
      (<any>this.$root).$emit('onNotifConversationRefreshList', msg);
      this.$root.$emit('chat::listenConversation', msg);
    });

    this.subscribeUserOnline = this.socketService().subscribeUserOnline((msg: ISocketMessage) => {
      let userIdsOnline = this.$store.getters.userIdsOnline;
      let foundIdx = userIdsOnline.findIndex(x => x === msg.actionBy);
      if (foundIdx > -1 && msg.isOnline) {
        //found and online
      } else if (foundIdx > -1 && !msg.isOnline) {
        //found and online
        userIdsOnline.splice(foundIdx, 1);
      } else if (foundIdx <= -1 && msg.isOnline) {
        userIdsOnline.unshift(msg.actionBy);
      }

      this.$store.commit('userIdsOnline', userIdsOnline);
    });

    this.subscribeUserOnlineCb = this.socketService().subscribeUserOnlineCB((msg: ISocketMessage) => {
      let userIdsOnlineCb = this.$store.getters.userIdsOnlineCb;
      let foundIdx = userIdsOnlineCb.findIndex(x => x === msg.actionBy);
      if (foundIdx > -1 && msg.isOnline) {
        //found and online
      } else if (foundIdx > -1 && !msg.isOnline) {
        //found and online
        userIdsOnlineCb.splice(foundIdx, 1);
      } else if (foundIdx <= -1 && msg.isOnline) {
        userIdsOnlineCb.unshift(msg.actionBy);
      }

      this.$store.commit('userIdsOnlineCb', userIdsOnlineCb);
    });
  }

  public beforeDestroy() {
    this.unsubscribe();
  }

  public unsubscribe() {
    if (this.subscriptionNotif) {
      this.subscriptionNotif.unsubscribe();
      this.subscriptionNotif = undefined;
    }
    if (this.subscriptionConversationBasedOnMember) {
      this.subscriptionConversationBasedOnMember.unsubscribe();
      this.subscriptionConversationBasedOnMember = undefined;
    }

    if (this.subscribeUserOnline) {
      this.subscribeUserOnline.unsubscribe();
      this.subscribeUserOnline = undefined;
    }

    if (this.subscribeUserOnlineCb) {
      this.subscribeUserOnlineCb.unsubscribe();
      this.subscribeUserOnlineCb = undefined;
    }

    if (this.subscribeNotifInbox) {
      this.subscribeNotifInbox.unsubscribe();
      this.subscribeNotifInbox = undefined;
    }

    if (this.subscribeNotifCb) {
      this.subscribeNotifCb.unsubscribe();
      this.subscribeNotifCb = undefined;
    }
  }

  public initSocketLiveAgent() {
    this.unsubscribeLiveAgent();
  }

  public unsubscribeLiveAgent(): void {
    if (this.subscriptionLiveAgentConversationId) {
      this.subscriptionLiveAgentConversationId.unsubscribe();
      this.subscriptionLiveAgentConversationId = undefined;
    }
  }
}
