
import axios from 'axios';
import { IPayVwBalanceCore } from '@/shared/model/pay-vw-balance-core.model';
import { CmApp } from '@/shared/model/enumerations/cm-app.model';
import { IPayVwTransactionSummaries } from '@/shared/model/paw-vw-transaction-summaries.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';


const baseApiUrlVwBalance = 'services/payment/api/pay-vw-balance-cores';
const baseApiUrlVwTransactionSummary = 'services/payment/api/pay-vw-transaction-summaries';
const baseApiUrlPayVwBillingHistoryPayment = 'services/payment/api/pay-vw-billing-history-payments';
const baseApiUrlPayVwBillingHistoryIncome = 'services/payment/api/pay-vw-billing-history-incomes';
const baseApiUrlPayVwBillingHistoryRefund = 'services/payment/api/pay-vw-billing-history-refunds';
const baseApiUrlPayVwBillingHistoryTransaction = 'services/payment/api/pay-vw-billing-history-transactions';
const baseApiUrlPayVwBillingHistoryTopup = 'services/payment/api/pay-vw-billing-history-topups';
const baseApiUrlPayVwBillingHistoryWithdraw = 'services/payment/api/pay-vw-billing-history-withdraws';

export default class PPayService {
  public findVwBalanceByUserId(app: CmApp, userId: string): Promise<IPayVwBalanceCore> {
    return new Promise<IPayVwBalanceCore>((resolve, reject) => {
      // http://localhost:8085/api/pay-vw-balance-cores/CB_APP/by-login
      axios
        .get(baseApiUrlVwBalance + '/' + app + '/by-user-id/'+ userId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findVwTransactionSummaryByUserId( userId: string): Promise<IPayVwTransactionSummaries> {
    return new Promise<IPayVwTransactionSummaries>((resolve, reject) => {
      // http://localhost:8085/api/pay-vw-balance-cores/CB_APP/by-login
      axios
        .get(baseApiUrlVwTransactionSummary +  '/by-user-id/'+ userId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryPayment( paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryPayment}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryIncome(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryIncome}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryRefund( paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryRefund}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryTransaction( paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryTransaction}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryTopup( paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryTopup}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryWithdraw( paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryWithdraw}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  
  // =================================
}
