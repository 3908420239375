import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICmAttachmentAuth } from '@/shared/model/cm-attachment-auth.model';

const baseApiUrl = 'services/crmcorems/api/cm-attachment-auths';

export default class PCmAttachmentAuthService {
  public find(id: number): Promise<ICmAttachmentAuth> {
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICmAttachmentAuth): Promise<ICmAttachmentAuth> {
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICmAttachmentAuth): Promise<ICmAttachmentAuth> {
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICmAttachmentAuth): Promise<ICmAttachmentAuth> {
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createTemp(entity: ICmAttachmentAuth, callBack: Function, callbackReject?: Function): Promise<ICmAttachmentAuth> {
    let tmpBlob = null;
    if (entity.blob) {
      entity.att = '';
      tmpBlob = entity.blob;
      delete entity.blob;
    }
    return new Promise<ICmAttachmentAuth>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/tmp`, entity)
        .then(res => {
          const result: ICmAttachmentAuth = res.data;
          this.upload(result, result.uploadLink, entity.attContentType, tmpBlob, callBack).then(() => {
            resolve(result);
          });
        })
        .catch(e => reject(new Error(e)));
    });
  }

  private upload(result: any, uploadLink: string, type: string, fileBlob: Blob, callBack: Function): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log('Request canceled in upload:', err.message);
          }
          reject(err);
        });
    });
  }
}
