import { CmVwUser, ICmVwUser } from '@/shared/model/cm-vw-user.model';
import { IKeycloakToken } from '@/shared/model/keycloak-token.model';
import { Module } from 'vuex';

export interface AccountStateStorable {
  logon: boolean;
  userIdentity: null | any;
  authenticated: boolean;
  ribbonOnProfiles: string;
  activeProfiles: string;
  currentUser: ICmVwUser;
  hasSuperAgent: boolean;
  hasCaseAgent: boolean;
  hasVerificationAgent: boolean;
  hasCustomerSupport: boolean;
  hasItSupport: boolean;
  hasFinanceTeam: boolean;
  hasMarketingTeam: boolean;
  hasMarketingManager: boolean;
  hasManagerial: boolean;
  hasDirector: boolean;
  keycloakToken: IKeycloakToken;
}

export const defaultAccountState: AccountStateStorable = {
  logon: false,
  userIdentity: null,
  authenticated: false,
  ribbonOnProfiles: '',
  activeProfiles: '',
  currentUser: new CmVwUser(),
  hasSuperAgent: false,
  hasCaseAgent: false,
  hasVerificationAgent: false,
  hasCustomerSupport: false,
  hasItSupport: false,
  hasFinanceTeam: false,
  hasMarketingTeam: false,
  hasMarketingManager: false,
  hasManagerial: false,
  hasDirector: false,
  keycloakToken: null,
};

export const accountStore: Module<AccountStateStorable, any> = {
  state: { ...defaultAccountState },
  getters: {
    logon: state => state.logon,
    account: state => state.userIdentity,
    authenticated: state => state.authenticated,
    activeProfiles: state => state.activeProfiles,
    ribbonOnProfiles: state => state.ribbonOnProfiles,
    currentUser: state => state.currentUser,
    getSuperAgent: state => state.hasSuperAgent,
    getCaseAgent: state => state.hasCaseAgent,
    getVerificationAgent: state => state.hasVerificationAgent,
    getCustomerSupport: state => state.hasCustomerSupport,
    getItSupport: state => state.hasItSupport,
    getFinanceTeam: state => state.hasFinanceTeam,
    getMarketingTeam: state => state.hasMarketingTeam,
    getMarketingManager: state => state.hasMarketingManager,
    getManagerial: state => state.hasManagerial,
    getDirector: state => state.hasDirector,
    keycloakToken: state => state.keycloakToken,
  },
  mutations: {
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = true;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
      state.currentUser = new CmVwUser();
      state.hasCaseAgent = false;
      state.hasSuperAgent = false;
      state.hasCustomerSupport = false;
      state.hasFinanceTeam = false;
      state.hasMarketingTeam = false;
      state.hasItSupport = false;
      state.hasManagerial = false;
      state.hasVerificationAgent = false;
      Object.assign(state, defaultAccountState);
    },
    setActiveProfiles(state, profile) {
      state.activeProfiles = profile;
    },
    setRibbonOnProfiles(state, ribbon) {
      state.ribbonOnProfiles = ribbon;
    },
    currentUser(state, param) {
      state.currentUser = param;
    },
    setSuperAgent(state, role) {
      state.hasSuperAgent = role;
    },
    setCaseAgent(state, role) {
      state.hasCaseAgent = role;
    },
    setVerificationAgent(state, role) {
      state.hasVerificationAgent = role;
    },
    setCustomerSupport(state, role) {
      state.hasCustomerSupport = role;
    },
    setItSupport(state, role) {
      state.hasItSupport = role;
    },
    setFinanceTeam(state, role) {
      state.hasFinanceTeam = role;
    },
    setMarketingTeam(state, role) {
      state.hasMarketingTeam = role;
    },
    setMarketingManager(state, role) {
      state.hasMarketingManager = role;
    },
    setManagerial(state, role) {
      state.hasManagerial = role;
    },
    setDirector(state, role) {
      state.hasDirector = role;
    },
    keycloakToken(state, keycloakToken) {
      state.keycloakToken = keycloakToken;
    },
  },
};
