import { Module } from 'vuex';
import { ICbEducation } from '@/shared/model/cb-education.model';

export interface ContentTextStore {
  cbEducations: ICbEducation[];
}

export const defaultState: ContentTextStore = {
  cbEducations: [],
};

export const contentTextStore: Module<ContentTextStore, any> = {
  state: { ...defaultState },
  getters: {
    cbEducations: state => state.cbEducations,
  },
  mutations: {
    cbEducations(state, param) {
      state.cbEducations = param;
    },
  },
};
