import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';

import '@/shared/config/dayjs';
import { Inject } from 'vue-property-decorator';
import PCmSopInputedAggService from './modules/services/cm-sop-inputed-agg.services';
import { ICmSopInputedAgg } from './shared/model/cm-sop-inputed-agg.model';
import PCbAttachmentRestrictService from './modules/services/cb-attachment-restrict.service';
import { CbAttachmentRestrict, ICbAttachmentRestrict } from './shared/model/cb-attachment-restrict.model';
import PCmAttachmentAuthService from './modules/services/cm-attachment-auth.service';
import PCbTextNoAuthService from './modules/services/cb-text-no-auth.service';
import PCmSopInputedAggAttService from './modules/services/cm-sop-inputed-agg-att.service';
import { ICmSopInputedAggAtt } from './shared/model/cm-sop-inputed-agg-att.model';
import PCbAttachmentNoAuthService from './modules/services/cb-attachment-no-auth.service';
import PCbPawDataVerification4CrmService from './modules/services/cb-paw-data-verification-4-crm.service';
import { ICbPawDataVerification4Crm } from './shared/model/cb-paw-data-verification-4-crm.model';
import PCbUserCrtVerificationService from './modules/services/cb-user-crt-verification.service';
import { ICbUserCrtVerification } from './shared/model/cb-user-crt-verification.model';
import AppLov from './app-lov';
import AppSocket from './app-socket.component';
import AccountService from './account/account.service';
import AppRouteManagemet from './app-route-management.component';
import JhiDataUtils from './shared/data/data-utils.service';
import { IProgressUpload } from './shared/model/progress-upload';
import CProgressUpload from './components/c-progress-upload/c-progress-upload.vue';
@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,
    CProgressUpload,
    'jhi-footer': JhiFooter,
  },
  mixins: [AppLov, AppSocket, AppRouteManagemet],
})
export default class App extends JhiDataUtils {
  // * ============= inject services =============
  @Inject('pCmSopInputedAggService') private pCmSopInputedAggService: () => PCmSopInputedAggService;
  @Inject('pCbAttachmentRestrictService') private pCbAttachmentRestrictService: () => PCbAttachmentRestrictService;
  @Inject('pCmAttachmentAuthService') private pCmAttachmentAuthService: () => PCmAttachmentAuthService;
  @Inject('pCbTextNoAuthService') private pCbTextNoAuthService: () => PCbTextNoAuthService;
  @Inject('pCmSopInputedAggAttService') private pCmSopInputedAggAttService: () => PCmSopInputedAggAttService;
  @Inject('pCbAttachmentNoAuthService') private pCbAttachmentNoAuthService: () => PCbAttachmentNoAuthService;
  @Inject('pCbPawDataVerification4CrmService') private pCbPawDataVerification4CrmService: () => PCbPawDataVerification4CrmService;
  @Inject('pCbUserCrtVerificationService') private pCbUserCrtVerificationService: () => PCbUserCrtVerificationService;
  @Inject('accountService') private accountService: () => AccountService;
  // * ============= inject services =============

  // * ============= variables =============

  public visibleLogoBti = false;
  public visibleRegister = false;
  public visibleMenuBar = false;
  public isSaving = false;
  public isFetching = false;
  public progressUploads: IProgressUpload[] = [];
  public closeProgressUploadMini() {
    (<any>this.$refs.toastProgressUpload).hide();
  }

  // * ============= variables =============

  public mounted() {
    this.$root.$emit('onVisibleMenuBar', false);
    this.$root.$emit('onVisibleLogoBti', false);
    this.$root.$emit('onVisibleRegister', false);
  }
  public created() {
    this.$root.$off('onVisibleMenuBar');
    this.$root.$on('onVisibleMenuBar', value => {
      this.visibleMenuBar = value;
    });

    this.$root.$off('onSaveCmSopInputedAgg');
    this.$root.$on('onSaveCmSopInputedAgg', (value: ICmSopInputedAgg, isEmit: boolean, cmSopInputedAggAtts: ICmSopInputedAggAtt[]) => {
      this.createSopInputedAgg(value, isEmit, cmSopInputedAggAtts);
    });

    this.$root.$off('onFindCbAttRestrict');
    this.$root.$on('onFindCbAttRestrict', (id: number) => {
      this.findOneCbAttRestrict(id);
    });

    this.$root.$off('onFindCmAttAuth');
    this.$root.$on('onFindCmAttAuth', (id: number, index: number) => {
      this.findOneCmAttachment(id, index);
    });

    this.$root.$off('onFindCbAttNoAuth');
    this.$root.$on('onFindCbAttNoAuth', (id: number) => {
      this.findCbAttNoAuth(id);
    });

    this.$root.$off('onSaveCbPawDataVerificaiton');
    this.$root.$on('onSaveCbPawDataVerificaiton', (cbPawDataVerification4Crm: ICbPawDataVerification4Crm, isEmit: boolean) => {
      this.submitCbPawDataVerificaiton(cbPawDataVerification4Crm, isEmit);
    });

    this.$root.$off('onSaveCbUserCrtVerificaiton');
    this.$root.$on('onSaveCbUserCrtVerificaiton', (cbUserCrtVerification: ICbUserCrtVerification, isEmit: boolean) => {
      this.submitCbUserCrtVerification(cbUserCrtVerification, isEmit);
    });

    this.$root.$off('onVisibleLogoBti');
    this.$root.$on('onVisibleLogoBti', value => {
      this.visibleLogoBti = value;
    });

    this.$root.$off('onVisibleRegister');
    this.$root.$on('onVisibleRegister', value => {
      this.visibleRegister = value;
    });
    (<any>this.$root).$off('app::progressUploadDownload');
    (<any>this.$root).$on('app::progressUploadDownload', (progressUploads: IProgressUpload[]) => {
      this.progressUploads = progressUploads;
      const progress = progressUploads.filter(x => x.progress == 100);
      console.log('filteredProgress', progress);
      console.log('filteredProgress 2', progressUploads);
      if (progress.length == progressUploads.length) {
        setTimeout(() => {
          this.closeProgressUploadMini();
        }, 1000);
      }
    });

    (<any>this.$root).$off('app::getProgressUploadDownload');
    (<any>this.$root).$on('app::getProgressUploadDownload', (callBack: (progress: IProgressUpload[]) => void) => {
      callBack(this.progressUploads);
    });

    (<any>this.$root).$off('app::showProgressUploadDownload');
    (<any>this.$root).$on('app::showProgressUploadDownload', isShow => {
      if (isShow) {
        (<any>this.$refs.toastProgressUpload).show();
        (<any>this.$refs.toastProgressUpload).toaster = 'b-toaster-top-center';
      } else this.closeProgressUploadMini();
    });
  }

  public createSopInputedAgg(cmSopInputedAgg: ICmSopInputedAgg, isEmit: boolean, cmSopInputedAggAtts: ICmSopInputedAggAtt[]) {
    this.isSaving = true;

    if (cmSopInputedAgg.id) {
      this.pCmSopInputedAggService()
        .partialUpdate(cmSopInputedAgg)
        .then(res => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .catch(err => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      this.pCmSopInputedAggService()
        .create(cmSopInputedAgg)
        .then(res => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .catch(err => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }

  public b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public findOneCbAttRestrict(id) {
    this.isFetching = true;

    this.pCbAttachmentRestrictService()
      .find(id)
      .then(async res => {
        this.isFetching = false;
        let image: ICbAttachmentRestrict = new CbAttachmentRestrict();
        if (res.attContentType == 'application/pdf') {
          const blob: any = this.b64toBlob(res.attTrans, 'application/pdf');

          const file = new File([blob], res.name, { lastModified: 1534584790000 });
          const thumbnail = (await this.generatePdfThumbnail(file, 2000)).split(',')[1];
          image = {
            att: res.att,
            attContentType: 'image/png',
            attTrans: thumbnail,
            cbPawOrderFile: res.cbPawOrderFile,
            createdBy: res.createdBy,
            createdDate: res.createdDate,
            id: res.id,
            isActive: res.isActive,
            lastModifiedBy: res.lastModifiedBy,
            lastModifiedDate: res.lastModifiedDate,
            name: res.name,
          };

          this.$root.$emit('afterFindCbAttRes', image);
        } else {
          this.$root.$emit('afterFindCbAttRes', res);
        }
      })
      .catch(err => {
        this.isFetching = false;
      });
  }

  public findOneCmAttachment(id, index) {
    this.isFetching = true;

    this.pCmAttachmentAuthService()
      .find(id)
      .then(res => {
        this.isFetching = false;
        this.$root.$emit('afterFindCmAttAuth', res, index);
      })
      .catch(err => {
        this.isFetching = false;
      });
  }

  public findCbAttNoAuth(id) {
    this.isFetching = true;

    this.pCbAttachmentNoAuthService()
      .find(id)
      .then(res => {
        this.isFetching = false;
        this.$root.$emit('afterFindCbAttNoAuth', res);
      })
      .catch(err => {
        this.isFetching = false;
      });
  }

  public submitCbPawDataVerificaiton(cbPawDataVerification4Crm: ICbPawDataVerification4Crm, isEmit: boolean) {
    if (cbPawDataVerification4Crm.id) {
      this.pCbPawDataVerification4CrmService()
        .update(cbPawDataVerification4Crm)
        .then(param => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .catch(error => {
          this.isSaving = false;
        });
    } else {
      this.pCbPawDataVerification4CrmService()
        .create(cbPawDataVerification4Crm)
        .then(param => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .catch(error => {
          this.isSaving = false;
        });
    }
  }

  public submitCbUserCrtVerification(cbUserCrtVerification: ICbUserCrtVerification, isEmit: boolean) {
    this.isSaving = true;
    if (cbUserCrtVerification.id) {
      this.pCbUserCrtVerificationService()
        .update(cbUserCrtVerification)
        .then(param => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .catch(error => {
          this.isSaving = false;
        });
    } else {
      this.pCbUserCrtVerificationService()
        .create(cbUserCrtVerification)
        .then(param => {
          this.isSaving = false;
          if (isEmit) {
            this.$root.$emit('afterSaveCmSopInputedAgg');
          }
        })
        .catch(error => {
          this.isSaving = false;
        });
    }
  }
}
