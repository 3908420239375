import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICmVwTktChatAccepted } from '@/shared/model/cm-vw-tkt-chat-accepted.model';
import { ICmVwTktChatNotAccepted } from '@/shared/model/cm-vw-tkt-chat-not-accepted.model';

const baseApiUrl = 'services/crmcorems/api/cm-vw-tkt-chat-not-accepteds';

export default class PCmVwTktChatNotAcceptedService {
  
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

}
