import axios from 'axios';

import { ICmTktAtt } from '@/shared/model/cm-tkt-att.model';

const baseApiUrl = 'services/crmcorems/api/cm-tkt-atts';

export default class PCmTktAttService {
  public find(id: number): Promise<ICmTktAtt> {
    return new Promise<ICmTktAtt>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, {params})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICmTktAtt, type: string, fileBlob: Blob, callBack: Function): Promise<ICmTktAtt> {
    if (entity.cmAttachmentRestrict) {
      entity.cmAttachmentRestrict.att = '';
      delete entity.cmAttachmentRestrict.blob;
    }
    return new Promise<ICmTktAtt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          var result: ICmTktAtt = res.data;
          resolve(this.upload(result, result.uploadloadLinks, type, fileBlob, callBack));
        })
        .catch(err => {
          reject(err);
        });
    });
  }


  public update(entity: ICmTktAtt): Promise<ICmTktAtt> {
    return new Promise<ICmTktAtt>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICmTktAtt): Promise<ICmTktAtt> {
    return new Promise<ICmTktAtt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }


  public upload(result: ICmTktAtt, uploadLink: string, type: string, fileBlob: Blob, callBack: Function): Promise<any> {
    var config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(signUrlRes => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(signUrlErr);
        });
    });
  }
}
