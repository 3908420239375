import axios from 'axios';

import { ICbVwPartnerBalance } from '@/shared/model/cb-vw-partner-balance.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-partner-balances';

export default class PCbVwPartnerBalanceService {
  public find(id: number): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public findById(userId: string): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-user-id/${userId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbVwPartnerBalance): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbVwPartnerBalance): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbVwPartnerBalance): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
