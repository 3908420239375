import { ICmRegion } from '@/shared/model/cm-region.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import axios, { AxiosResponse } from 'axios';

const baseApiUrl = 'services/crmcorems/api/cm-vw-regions';
const baseApiUrl2 = 'services/crmcorems/api/cm-regions';

export default class PCmRegionsService {
  public retrieve(paginationQuery: any): Promise<AxiosResponse<ICmRegion[]>> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseApiUrl}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res: AxiosResponse<ICmRegion[]>) => {
          resolve(res);
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  public find(id: number): Promise<AxiosResponse<ICmRegion>> {
    return new Promise((resolve, reject) => {
      axios.get(`${baseApiUrl2}/${id}`).then((res: AxiosResponse<ICmRegion>) => {
        resolve(res);
      }).catch((error) => {
        reject(new Error(error));
      })
    })
  }

  public create(entity: ICmRegion): Promise<AxiosResponse<ICmRegion>> {
    return new Promise((resolve, reject) => {
      axios.post(baseApiUrl2, entity).then((res: AxiosResponse<ICmRegion>) => {
        resolve(res);
      }).catch((error) => {
        reject(new Error(error));
      })
    })
  }

  public update(entity: ICmRegion): Promise<AxiosResponse<ICmRegion>> {
    return new Promise((resolve, reject) => {
      axios.patch(`${baseApiUrl2}/${entity.id}`, entity).then((res: AxiosResponse<ICmRegion>) => {
        resolve(res);
      }).catch((error) => {
        reject(new Error(error));
      })
    })
  }

  public delete(id: number): Promise<AxiosResponse<ICmRegion>> {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseApiUrl2}/${id}`).then((res: AxiosResponse<ICmRegion>) => {
        resolve(res);
      }).catch((error) => {
        reject(new Error(error));
      })
    })
  }
}
