export default function buildPaginationQueryOpts(paginationQuery) {
  if (paginationQuery) {
    let sorts = '';
    if (paginationQuery.sort)
      for (const idx of Object.keys(paginationQuery.sort)) {
        if (sorts.length > 0) {
          sorts += '&';
        }
        sorts += 'sort=' + paginationQuery.sort[idx];
      }

    // Jika ada param by wildan
    let params = '';
    if (paginationQuery.params)
      for (const key in paginationQuery.params) {
        if (Object.prototype.hasOwnProperty.call(paginationQuery.params, key)) {
          const element = paginationQuery.params[key];
          if (params.length) {
            params += '&';
          }
          params += key + '=' + element;
        }
      }

    
    return `${paginationQuery.paramSearchStr ? paginationQuery.paramSearchStr : ''}${params}&${sorts}&page=${paginationQuery.page}&size=${
      paginationQuery.size
    }`;

    // return `${params}&${sorts}&page=${paginationQuery.page}&size=${paginationQuery.size}`;
  }
  return '';
}
