import App from '@/app.component';
import { CmApp } from '@/shared/model/enumerations/cm-app.model';
import { Module } from 'vuex';

export interface TabList {
  index: number;
  name: string;
  lastRoutePath?: string;
  isActive: boolean;
  ticketViewType?: string;
}

export interface CrmStateStore {
  isShowSidebar: boolean;
  showLogoBti: string;
  isShowRegister: boolean;
  isShowProfil: boolean;
  isShowChatAnotherAgent: boolean;
  isShowBtnStart: boolean;
  isTypeLogo: CmApp;
  isShowCSolutionArticleSearch: boolean;
  isSendContentInbox: boolean;
  isLogo: string;
  isRowTabs: string[];
  seletedTickets: String;
  isControlPanel: boolean;
  hasShowTab: Boolean;
  tabList: TabList[];
}

export const defaultCrmState: CrmStateStore = {
  isShowSidebar: true,
  showLogoBti: 'BTI',
  isShowRegister: false,
  isShowProfil: true,
  isShowChatAnotherAgent: false,
  isShowBtnStart: false,
  isTypeLogo: null,
  isShowCSolutionArticleSearch: false,
  isSendContentInbox: false,
  isLogo: '',
  isRowTabs: [],
  seletedTickets: 'ALLTICKET',
  isControlPanel: false,
  hasShowTab: true,
  tabList: [],
};

export const crmStore: Module<any, any> = {
  state: { ...defaultCrmState },

  mutations: {
    setIsShowSidebar(state, data) {
      state.isShowSidebar = data;
    },
    setShowLogoBti(state, data) {
      state.showLogoBti = data;
    },
    setIsShowRegister(state, data) {
      state.isShowRegister = data;
    },
    setIsShowProfil(state, data) {
      state.isShowProfil = data;
    },
    setIsShowChatAnotherAgent(state, data) {
      state.isShowChatAnotherAgent = data;
    },
    setIsShowBtnStart(state, data) {
      state.isShowBtnStart = data;
    },
    setIsTypeLogo(state, data) {
      state.isTypeLogo = data;
    },
    setIsShowCSolutionArticleSearch(state, data) {
      state.isShowCSolutionArticleSearch = data;
    },
    setIsSendContentInbox(state, data) {
      state.isSendContentInbox = data;
    },
    setIsLogo(state, data) {
      state.isLogo = data;
    },
    setSeletedTickets(state, data) {
      state.seletedTickets = data;
    },
    setIsControlPanel(state, data) {
      state.isControlPanel = data;
    },
    setShowTab(state, data) {
      state.hasShowTab = data;
    },
    setTabList(state, data) {
      state.tabList = data;
    },
    clearTabList(state) {
      state.tabList = [];
    },
  },
  getters: {
    getIsShowSidebar: state => state.isShowSidebar,
    getShowLogoBti: state => state.showLogoBti,
    getIsShowRegister: state => state.isShowRegister,
    getIsShowChatAnotherAgent: state => state.isShowChatAnotherAgent,
    getIsShowBtnStart: state => state.isShowBtnStart,
    getIsTypeLogo: state => state.isTypeLogo,
    getIsShowCSolutionArticleSearch: state => state.isShowCSolutionArticleSearch,
    getIsSendContentInbox: state => state.isShowCSolutionArticleSearch,
    getIsLogo: state => state.isLogo,
    getSeletedTickets: state => state.seletedTickets,
    getIsControlPanel: state => state.isControlPanel,
    getShowTab: state => state.hasShowTab,
    getTabList: state => state.tabList,
  },
};
