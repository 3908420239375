import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ICbVwPaw } from '@/shared/model/cb-vw-paw.model';
import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { ICbAttachmentNoAuth } from '@/shared/model/cb-attachment-no-auth.model';
import { ICbPaw } from '@/shared/model/cb-paw.model';
import { ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';

const baseApiUrl = 'services/cbcorems/api';
const baseApiUrl2 = 'services/cbuserms/api';
export default class PCbMainService {
  public findOneCbVwPaws(id: number): Promise<ICbVwPaw> {
    return new Promise<ICbVwPaw>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-vw-paws/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findTextNoAuth(id: number): Promise<ICbTextNoAuth> {
    return new Promise<ICbTextNoAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-text-no-auths/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbPawPkgAggByPawId(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paw-pkg-aggs?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbPawUserCrtAgg(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paw-user-crt-aggs?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbPawPortofolios(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paw-portofolios?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbPawPortofolioDocs(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paw-portofolio/docs?cbPawId.equals${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findCbAttNoAuth(id: number): Promise<ICbAttachmentNoAuth> {
    return new Promise<ICbAttachmentNoAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-attachment-no-auths/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbTagAggByPawId(cbPawId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paw-tag-aggs?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findCbPaw(id: number): Promise<ICbPaw> {
    return new Promise<ICbPaw>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paws/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbPawToolAgg(cbpawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-paw-tool-aggs?cbPawId.equals=${cbpawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbVwPawMetaValuesByPawId(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-vw-paw-meta-values?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbVwPawCrtsByPawId(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-vw-paw-crts?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbVwPawDetailAttsByPawId(cbPawId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/cb-vw-paw-detail-atts?cbPawId.equals=${cbPawId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findOneCbVwUser(id: string): Promise<ICbVwUser> {
    return new Promise<ICbVwUser>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-vw-users/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findOneCbUserInfo(id: string): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-user-infos/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserSocialMedias(userId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-user-social-medias?cbUserInfoId.equals=${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbUserEducations(userInfoId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-user-educations?cbUserInfoId.equals=${userInfoId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbUserDatas(userId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-user-data?cbUserInfoId.equals=${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findOneCbAttachmentRestrict(id: number): Promise<ICbAttachmentRestrict> {
    return new Promise<ICbAttachmentRestrict>((resolve, reject) => {
      axios
        .get(`${baseApiUrl2}/cb-attachment-restricts/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deactivePaw(id: number): Promise<ICbPaw> {
    return new Promise<ICbPaw>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/cb-paws/force-deactive-4-crm/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public activatePaw(id: number): Promise<ICbPaw> {
    return new Promise<ICbPaw>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/cb-paws/force-activate-4-crm/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
