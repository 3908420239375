import * as SockJS from 'sockjs-client';
import { map } from 'rxjs';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { RxStomp } from '@stomp/rx-stomp';
import { ISocketMessage } from '@/shared/model/socket-message.model';
import axios from 'axios';
import { MEMBER_AGENT_ACCOUNT_NO_LOGIN } from '@/constants';
import AccountService from '@/account/account.service';
import jwt_decode from 'jwt-decode';

import dayjs from 'dayjs';

const API_PUBLISH = 'services/btisocketkafkagw/api/btisocketkafkagw-kafka/publish'; ///using dev.env to avoid cors
const SOCKET_APP = 'websocket/crm';
const API_GENERAL = 'services/btisocketkafkagw/api/btisocketkafkagw-kafka'; ///using dev.env to avoid cors

const DESTINATION_USER_ONLINE = '/topic/crm/user_online';
const DESTINATION_USER_ONLINE_CB = '/topic/cb/user_online';
const DESTINATION_NOTIF_USER_LOGIN = '/topic/crm/notif'; //.login
const DESTINATION_INBOX = '/topic/crm/inbox';
const DESTINATION_CB_NOTIF_USER_LOGIN = '/topic/cb/notif'; //.login

const DESTINATION_CONVERSATION_POPUP = '/topic/crm/conversation_popup';
const DESTINATION_CONVERSATION_MEMBER_CB = '/topic/la/conversation_member'; // /id user
const DESTINATION_CONVERSATION_id = '/topic/la/conversation_id'; // id conversation
const DESTINATION_LAST_SEEN = '/topic/la/conversation_last_seen';

export default class SocketService {
  private rxStomp: RxStomp;
  private authToken;

  constructor(private router: VueRouter, private store: Store<any>, private accountService: AccountService) {
    this.stomp = new RxStomp();
    this.router.afterEach(() => this.sendUserOnline());

    this.store.watch(
      (_state, getters) => getters.authenticated,
      (value, oldValue) => {
        if (value === oldValue) return;
        if (value) {
          this.connectCustome();
        }
        return this.disconnect();
      }
    );
  }

  public connectCustome() {
    this.accountService.retrieveUserToken().then(res => {
      //to get token and csrf, to ignore avoid csrf  null
      //dont put  to store, adehuh must check
      // localStorage.setItem('crm-jhi-authenticationToken', res); //test adehuh
      // this.authToken = res;
      console.log('1.prepare connect websocket>>>>>>>>');
      return this.connect();
    });
  }

  get stomp() {
    return this.rxStomp;
  }

  set stomp(rxStomp) {
    this.rxStomp = rxStomp;
    this.rxStomp.configure({
      debug: (msg: string): void => {
        console.log(new Date(), msg);
      },
    });
    this.rxStomp.connected$.subscribe(() => {
      this.sendUserOnline();
    });
  }

  private connect(): void {
    this.updateCredentials();
    this.retrieveUserIdsOnline();
    return this.rxStomp.activate();
  }

  private disconnect(): Promise<void> {
    return this.rxStomp.deactivate();
  }

  private buildUrl(): string {
    let url = '//' + process.env.SOCKET_URL + SOCKET_APP;
    if (this.authToken) {
      url += '?bearer=' + this.authToken;
    }

    return url;
  }

  public subscribNotifCb(observer) {
    return this.rxStomp
      .watch(DESTINATION_CB_NOTIF_USER_LOGIN + '/' + this.store.getters.account.login)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public sendUserOnline(): void {
    //using kafka
    // this.rxStomp.publish({
    //   destination: DESTINATION_ACTIVITY,
    //   body: JSON.stringify({ page: this.router.currentRoute.fullPath }),
    // });

    axios
      .post(`${API_GENERAL}/send-user-online/crm`)
      .then(res => {
      })
      .catch(err => {
      });
  }

  public isSessionLive(): void {
    //using kafka
    // this.rxStomp.publish({
    //   destination: DESTINATION_ACTIVITY,
    //   body: JSON.stringify({ page: this.router.currentRoute.fullPath }),
    // });

    axios
      .get(`${API_GENERAL}/is-session-live`)
      .then(res => {
        // if (!res.data) alert('isSessionLive=' + res.data);
      })
      .catch(err => {
      });
  }

  public publishMessage(entity: ISocketMessage): Promise<ISocketMessage> {
    return new Promise<ISocketMessage>((resolve, reject) => {
      axios
        .post(`${process.env.SOCKET_PUBLISH_URL ? process.env.SOCKET_PUBLISH_URL : API_PUBLISH}/crm`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public subscribeUserOnline(observer) {
    return this.rxStomp
      .watch(DESTINATION_USER_ONLINE)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeUserOnlineCB(observer) {
    return this.rxStomp
      .watch(DESTINATION_USER_ONLINE_CB)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }


  public subscribeConversationBasedOnMember(observer) {
    return this.rxStomp
      //.watch(DESTINATION_CONVERSATION_MEMBER + '/' + this.store.getters.account.id)
      .watch(DESTINATION_CONVERSATION_MEMBER_CB + '/' + MEMBER_AGENT_ACCOUNT_NO_LOGIN)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeConversationId(observer, conversationId?) {
    return this.rxStomp
      .watch(DESTINATION_CONVERSATION_id + '/' + conversationId)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeNotif(observer) {
    return this.rxStomp
      .watch(DESTINATION_NOTIF_USER_LOGIN + '/' + this.store.getters.currentUser.email)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeLastSeen(observer, conversationI) {
    return this.rxStomp
      .watch(DESTINATION_LAST_SEEN + '/' + conversationI)
      .pipe(
        map(imessage => {
          return JSON.parse(imessage.body);
        })
      )
      .subscribe(observer);
  }

  public retrieveUserIdsOnline(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_GENERAL + '/user-ids-online')
        .then(res => {
          this.store.commit('userIdsOnline', res.data);
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public subscribeInbox(observer) {
    return this.rxStomp
      .watch(DESTINATION_INBOX)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeConversationPopup(observer) {
    return this.rxStomp
      .watch(DESTINATION_CONVERSATION_POPUP)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  private updateCredentials(): void {
    const authToken = localStorage.getItem('crm-jhi-authenticationToken') || sessionStorage.getItem('crm-jhi-authenticationToken');

    this.rxStomp.configure({
      webSocketFactory: () => {
        //return new SockJS(this.buildUrl());//default
        console.log('>>>>>>>>>>webSocketFactory ');
        const r = new SockJS(this.buildUrl(), null, { headers: { Authorization: 'Bearer ' + this.authToken } });
        return r;
      },
      reconnectDelay: 1000,
      beforeConnect: async client => {
        console.log('Before Connect');
        const jwtAccessToken: any = jwt_decode(authToken);
        const expires_in = jwtAccessToken.exp * 1000;

        if (expires_in && dayjs(expires_in).isAfter(dayjs(new Date()))) {
          this.authToken = authToken;
          console.log('>>>>>>>>>>authToken', this.authToken);
        } else {
          console.log('>>>>>>>>>>authToken expired');
          // its will triger refresh token at interceptor
          await this.accountService.retrieveUserToken().then(res => {
            this.authToken = res;
          });
        }

        return new Promise((resolve, reject) => {
          // Perform any asynchronous operation here before connecting
          // Call resolve() if the operation is successful, otherwise call reject()
          resolve();
        });
      },
      correlateErrors: (error) => {
        console.error('Broker reported error: ' + error.headers['message']);
        console.error('Additional details: ' + error.body);
        return 'correlateErrors';
      }
    });
  }
}
