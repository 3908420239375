import axios from 'axios';

import { ICbVwSvcCat } from '@/shared/model/cb-vw-svc-cat.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = process.env.CBCOREMS_URL ? process.env.CBCOREMS_URL + 'api/cb-vw-svc-cats' : 'services/cbcorems/api/cb-vw-svc-cats';

export default class PCbVwSvcCatService {
  public find(id: number): Promise<ICbVwSvcCat> {
    return new Promise<ICbVwSvcCat>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByParams(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbVwSvcCat): Promise<ICbVwSvcCat> {
    return new Promise<ICbVwSvcCat>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbVwSvcCat): Promise<ICbVwSvcCat> {
    return new Promise<ICbVwSvcCat>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbVwSvcCat): Promise<ICbVwSvcCat> {
    return new Promise<ICbVwSvcCat>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
