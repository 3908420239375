import { Component, Vue } from 'vue-property-decorator';
import * as pdfjsLib from 'pdfjs-dist';
import axios from 'axios';

/**
 * An utility service for data.
 */
const pdfjsWorker = process.env.CDN_CJ + '/lib/pdfjs-2.3.200-dist/build/pdf.worker.js';
@Component
export default class JhiDataUtils extends Vue {
  /**
   * Method to abbreviate the text given
   */
  abbreviate(text, append = '...') {
    if (text.length < 30) {
      return text;
    }
    return text ? text.substring(0, 15) + append + text.slice(-10) : '';
  }

  /**
   * Method to find the byte size of the string provides
   */
  byteSize(base64String) {
    return this.formatAsBytes(this.size(base64String));
  }

  /**
   * Method to open file
   */
  openFile(contentType, data) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const objectURL = URL.createObjectURL(blob);
    const win = window.open(objectURL);
    if (win) {
      win.onload = () => URL.revokeObjectURL(objectURL);
    }
  }

  /**
   * Method to convert the file to base64
   */
  toBase64(file, cb) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e: any) => {
      const base64Data = e.target.result.substring(e.target.result.indexOf('base64,') + 'base64,'.length);
      cb(base64Data);
    };
  }

  /**
   * Method to clear the input
   */
  clearInputImage(entity, elementRef, field, fieldContentType, idInput) {
    if (entity && field && fieldContentType) {
      if (Object.prototype.hasOwnProperty.call(entity, field)) {
        entity[field] = null;
      }
      if (Object.prototype.hasOwnProperty.call(entity, fieldContentType)) {
        entity[fieldContentType] = null;
      }
      if (elementRef && idInput && elementRef.nativeElement.querySelector('#' + idInput)) {
        elementRef.nativeElement.querySelector('#' + idInput).value = null;
      }
    }
  }

  endsWith(suffix, str) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  paddingSize(value) {
    if (this.endsWith('==', value)) {
      return 2;
    }
    if (this.endsWith('=', value)) {
      return 1;
    }
    return 0;
  }

  size(value) {
    return (value.length / 4) * 3 - this.paddingSize(value);
  }

  formatAsBytes(size) {
    return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
  }

  setFileData(event, entity, field, isImage) {
    if (event && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (isImage && !/^image\//.test(file.type)) {
        return;
      }
      this.toBase64(file, base64Data => {
        entity[field] = base64Data;
        entity[`${field}ContentType`] = file.type;
      });
    }
  }

  /**
   * Method to download file
   */
  downloadFile(contentType, data, fileName) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = fileName;
    tempLink.target = '_blank';
    tempLink.click();
  }

  /**
   * Method to parse header links
   */
  parseLinks(header) {
    const links = {};

    if (header === null || header.indexOf(',') === -1) {
      return links;
    }
    // Split parts by comma
    const parts = header.split(',');

    // Parse each part into a named link
    parts.forEach(p => {
      if (p.indexOf('>;') === -1) {
        return;
      }
      const section = p.split('>;');
      const url = section[0].replace(/<(.*)/, '$1').trim();
      const queryString = { page: null };
      url.replace(new RegExp(/([^?=&]+)(=([^&]*))?/g), ($0, $1, $2, $3) => {
        queryString[$1] = $3;
      });
      let page = queryString.page;
      if (typeof page === 'string') {
        page = parseInt(page, 10);
      }
      const name = section[1].replace(/rel="(.*)"/, '$1').trim();
      links[name] = page;
    });
    return links;
  }

  public resizeImage({ file, maxSize }) {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
      let { width, height } = image;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);

      const dataUrl = canvas.toDataURL('image/jpeg');

      return this.dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = readerEvent => {
        image.onload = () => ok(resize());
        (<any>image).src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  public formatFromBytes(a: number, b = 2): string {
    if (!+a) return '0 Bytes';
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`;
  }

  public async generateVideoThumbnail(file: File, maxSize) {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        //resize
        if (canvas.width > canvas.height) {
          if (canvas.width > maxSize) {
            canvas.height *= maxSize / canvas.width;
            canvas.width = maxSize;
          }
        } else if (canvas.height > maxSize) {
          canvas.width *= maxSize / canvas.height;
          canvas.height = maxSize;
        }

        canvas.width = canvas.width;
        canvas.height = canvas.height;
        //end resize

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        video.pause();
        return resolve(canvas.toDataURL('image/png'));
      };
    });
  }

  public async generatePdfThumbnail(file: File, maxSize) {
    // alert(URL.createObjectURL(file));
    pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.URL_PDF_JS}`;
    const worker = new pdfjsLib.PDFWorker();
    const fileUrl = URL.createObjectURL(file);
    const pdf = await pdfjsLib.getDocument({ url: fileUrl, worker: worker }).promise.then(pdf => pdf);
    // MORE 1

    const page = await pdf.getPage(1);

    return new Promise<string>(resolve => {
      return resolve(this.makeThumbPdf(page));
    });
  }
  public async generatePdfToImage(file: File, maxSize) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.URL_PDF_JS}`;
    const worker = new pdfjsLib.PDFWorker();
    const fileUrl = URL.createObjectURL(file);
    const pdf = await pdfjsLib.getDocument({ url: fileUrl, worker: worker }).promise.then(pdf => pdf);
    // MORE 1

    const images: any = [];
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      await images.push(await this.makeThumbPdf(await pdf.getPage(pageNumber)));
    }

    return new Promise<string>(resolve => {
      return resolve(images);
    });
  }

  public makeThumbPdf(page) {
    // draw page to fit into 96x96 canvas
    const baseWidth = 20000;
    const baseHeight = 20000;

    let viewport = page.getViewport({ scale: 5.0 });
    viewport = page.getViewport({ scale: baseWidth / viewport.width });
    const canvas = document.createElement('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    return page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport }).promise.then(function () {
      return canvas.toDataURL('image/png');
    });
  }

  public createSnapshotOfElement(element, posX, posY, width, height, convertedSnapshotCallback) {
    // html2canvas(document.querySelector(element), {
    //   useCORS: true,
    //   allowTaint: false,
    // }).then(canvas => {
    //   const context = canvas.getContext('2d');
    //   const imageData = context.getImageData(posX, posY, canvas.width, canvas.height).data;
    //   const outputCanvas = document.createElement('canvas');
    //   const outputContext = outputCanvas.getContext('2d');
    //   outputCanvas.width = canvas.width;
    //   outputCanvas.height = canvas.height;
    //   const outputIData = outputContext.createImageData(canvas.width, canvas.height);
    //   outputIData.data.set(imageData);
    //   outputContext.putImageData(outputIData, 0, 0);
    //   //resize
    //   let { width, height } = outputCanvas;
    //   var maxSize = 600;
    //   if (width > height) {
    //     if (width > maxSize) {
    //       height *= maxSize / width;
    //       width = maxSize;
    //     }
    //   } else if (height > maxSize) {
    //     width *= maxSize / height;
    //     height = maxSize;
    //   }
    //   const canvasResult = document.createElement('canvas');
    //   canvasResult.width = width;
    //   canvasResult.height = height;
    //   canvasResult.getContext('2d').drawImage(outputCanvas, 0, 0, width, height);
    //   convertedSnapshotCallback(
    //     //use it to get the raw base64 data without format mention
    //     //outputCanvas.toDataURL().replace("data:image/png;base64,", "")
    //     // outputCanvas.toDataURL() //default
    //     canvasResult.toDataURL()
    //   );
    // });
  }
  convertBase64ToBlob(contentType, att) {
    const byteCharacters = atob(att);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const objectURL = URL.createObjectURL(blob);

    if (objectURL) {
      return objectURL;
    }
  }

  public bytetoBlob(bytes, type) {
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: type });
  }

  // public dataURItoBlob(dataURI) {
  //   const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
  //   const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  //   const max = bytes.length;
  //   const ia = new Uint8Array(max);
  //   for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  //   return new Blob([ia], { type: mime });
  // }

  downloadFileGoogle(url: string) {
    axios
      .get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {},
      })
      .then(res => {
        let headerLine = res.headers['content-disposition'];
        var filename = null;
        if (headerLine) {
          let startFileNameIndex = headerLine.indexOf('"') + 1;
          let endFileNameIndex = headerLine.lastIndexOf('"');
          let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        }

        var blob = new Blob([res.data], { type: res.headers['content-type'] });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (filename) link.download = filename;
        link.target = '_blank';
        link.click();
      })
      .catch(err => {});
  }

  base64ToBlobString(att, contentType) {
    const byteCharacters = atob(att);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const objectURL = URL.createObjectURL(blob);

    if (objectURL) {
      return objectURL;
    }
  }

  public dataURItoBlob(dataURI) {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  }

  public showToastOnWrongExtension(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.invalidUploadFile').toString());
  }

  public showToastOnExceededFileAmount(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.maxUploadFileLength', { length: 10 }).toString());
  }

  private showToast(msg: string): void {
    this.$root.$bvToast.toast(msg, {
      toaster: 'b-toaster-top-center',
      title: 'Warning',
      variant: 'warning',
      solid: true,
      autoHideDelay: 5000,
    });
  }
}
