import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICbVwPawPortfolio } from '@/shared/model/cb-vw-paw-portfolio.model';

const baseApiUrl = 'services/cbcorems/api/cb-vw-paw-portfolio-4-crms';

export default class PCbVwPawPortfolioService {
  public find(id: number): Promise<ICbVwPawPortfolio> {
    return new Promise<ICbVwPawPortfolio>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveByParams(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveCount(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/count`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbVwPawPortfolio): Promise<ICbVwPawPortfolio> {
    return new Promise<ICbVwPawPortfolio>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbVwPawPortfolio): Promise<ICbVwPawPortfolio> {
    return new Promise<ICbVwPawPortfolio>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbVwPawPortfolio): Promise<ICbVwPawPortfolio> {
    return new Promise<ICbVwPawPortfolio>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public download(downloadLink: string, type: string, callBack: Function): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${downloadLink}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(signUrlRes => {
          let base64 = null;
          const blob = new Blob([signUrlRes.data], { type: signUrlRes.headers['content-type'] });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            base64 = reader.result;
            resolve(base64);
          };
        })
        .catch(signUrlErr => {
          reject(false);
        });
    });
  }
}
