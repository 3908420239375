import axios from 'axios';

import { ICmVwSopInputedAgg } from '@/shared/model/cm-vw-sop-inputed-agg.model';

const baseApiUrl = 'services/crmcorems/api/cm-vw-sop-inputed-aggs';

export default class PCmVwSopInputedAggService {
  public find(id: number): Promise<ICmVwSopInputedAgg> {
    return new Promise<ICmVwSopInputedAgg>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICmVwSopInputedAgg): Promise<ICmVwSopInputedAgg> {
    return new Promise<ICmVwSopInputedAgg>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICmVwSopInputedAgg): Promise<ICmVwSopInputedAgg> {
    return new Promise<ICmVwSopInputedAgg>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICmVwSopInputedAgg): Promise<ICmVwSopInputedAgg> {
    return new Promise<ICmVwSopInputedAgg>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public download(downloadLink: string, type: string, callBack: Function): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${downloadLink}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(signUrlRes => {
          let base64 = null;
          const blob = new Blob([signUrlRes.data], { type: signUrlRes.headers['content-type'] });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            base64 = reader.result;
            resolve(base64);
          };
        })
        .catch(signUrlErr => {
          reject(false);
        });
    });
  }
}
