import axios, { AxiosResponse } from "axios";
import { ICmVwGlobalTargetAssignAchieveds } from "@/shared/model/cm-vw-global-target-assign-achieveds.model";
import buildPaginationQueryOpts from "@/shared/sort/sorts";

const baseApiUrl = "services/crmcorems/api/cm-vw-global-target-assign-achieveds";

export default class PCmVwGlobalTargetAssignAchievedsService {
  public retrieve(paginationQuery? : any, params? : any): Promise<AxiosResponse> {
    return new Promise<any>((resolve, reject) => {
      axios.get(`${baseApiUrl}?${params}${buildPaginationQueryOpts(paginationQuery)}`).then(res => {
        resolve(res);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }
}
