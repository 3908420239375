import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import PCbLovService from './modules/services/cb-lov.service';
import { CbLovType } from './shared/model/enumerations/cb-lov-type.model';
import ContentService from './modules/services/content.service';
@Component({
  components: {},
})
export default class AppLov extends Vue {
  @Inject('pCbLovService') public pCbLovService: () => PCbLovService;
  @Inject('contentService') public contentService: () => ContentService;
  private CONTENT_BANNER_BUYER = 'CONTENT_BANNER_BUYER';
  private CONTENT_BANNER_PARTNER = 'CONTENT_BANNER_PARTNER';
  private CONTENT_IMAGE = 'CONTENT_IMAGE';
  private TIME_ZONE = 'TIME_ZONE';
  private COUNTRY = 'COUNTRY';
  private PROVINCE = 'PROVINCE';
  private PHONE_CODE = 'PHONE_CODE';
  private CITY = 'CITY';
  private LANGUAGE_CODE = 'LANGUAGE_CODE';
  private LANGUAGE_LEVEL = 'LANGUAGE_LEVEL';
  private LANGUAGE = 'LANGUAGE';
  private MAJOR = 'MAJOR';

  created() {
    this.contentService().initRetrieveCbLovs(CbLovType.CONTENT_BANNER_BUYER);
    this.contentService().initRetrieveCbLovs(CbLovType.CONTENT_IMAGE);
    this.contentService().initRetrieveCbLovs(CbLovType.TIME_ZONE);
    this.contentService().initRetrieveCbLovs(CbLovType.COUNTRY);
    this.contentService().initRetrieveCbLovs(CbLovType.PROVINCE);
    this.contentService().initRetrieveCbLovs(CbLovType.CITY);
    this.contentService().initRetrieveCbLovs(CbLovType.LANGUAGE_CODE);
  }
}
