import axios from 'axios';

import { ICmSopInputedAggAtt } from '@/shared/model/cm-sop-inputed-agg-att.model';

const baseApiUrl = 'services/crmcorems/api/cm-sop-inputed-agg-atts';

export default class PCmSopInputedAggAttService {
  public find(id: number): Promise<ICmSopInputedAggAtt> {
    return new Promise<ICmSopInputedAggAtt>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveByParams(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public create(entity: ICmSopInputedAggAtt): Promise<ICmSopInputedAggAtt> {
  //   return new Promise<ICmSopInputedAggAtt>((resolve, reject) => {
  //     axios
  //       .post(`${baseApiUrl}`, entity)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public create(entity: ICmSopInputedAggAtt): Promise<ICmSopInputedAggAtt> {
    return new Promise<ICmSopInputedAggAtt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          // resolve(res.data);
          const result: ICmSopInputedAggAtt = res.data;
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICmSopInputedAggAtt): Promise<ICmSopInputedAggAtt> {
    return new Promise<ICmSopInputedAggAtt>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          const result: ICmSopInputedAggAtt = res.data;
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICmSopInputedAggAtt, callBack: Function, callbackReject?: Function): Promise<ICmSopInputedAggAtt> {
    let tmpBlob = null;
    if (entity.cmAttachmentAuth) {
      entity.cmAttachmentAuth.att = '';
      tmpBlob = entity.cmAttachmentAuth.blob;
      delete entity.cmAttachmentAuth.blob;
    }
    return new Promise<ICmSopInputedAggAtt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          const result: ICmSopInputedAggAtt = res.data;

          if (tmpBlob && result.uploadLink) {
            resolve(this.upload(result, result.uploadLink, entity.cmAttachmentAuth.attContentType, tmpBlob, callBack, callbackReject));
          } else {
            resolve(res.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private upload(
    result: ICmSopInputedAggAtt,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function,
  ): Promise<any> {
    // Store upload progress in localStorage
    const STORAGE_KEY = `upload_progress_${result.id}`;
    let uploadedBytes = parseInt(localStorage.getItem(STORAGE_KEY) || '0');

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
        'Content-Range': `bytes ${uploadedBytes}-${fileBlob.size - 1}/${fileBlob.size}`,
      },
      onUploadProgress: uploadEvent => {
        const totalLoaded = uploadedBytes + uploadEvent.loaded;
        const progress = Math.round((totalLoaded / fileBlob.size) * 100);
        callBack(progress);
        // Save progress
        localStorage.setItem(STORAGE_KEY, totalLoaded.toString());
      },
    };

    return new Promise<any>((resolve, reject) => {
      const makeRequest = async (retries = 3, delay = 1000) => {
        try {
          // Create slice of remaining data to upload
          console.log('retries', retries);
          const remainingBlob = fileBlob.slice(uploadedBytes);

          const res = await axios.put(`${uploadLink}`, remainingBlob, config);
          // Clear stored progress on success
          localStorage.removeItem(STORAGE_KEY);
          resolve(result);
        } catch (error) {
          console.error('Upload error:', error);

          if (retries > 0) {
            // Wait for connection to be restored
            await new Promise(resolve => {
              const checkConnection = () => {
                if (navigator.onLine) {
                  console.log('checkConnection Online');
                  resolve(true);
                } else {
                  console.log('checkConnection Offline');
                  setTimeout(checkConnection, delay);
                }
              };
              checkConnection();
            });

            // Reset progress to start from beginning on error
            uploadedBytes = 0;
            localStorage.setItem(STORAGE_KEY, '0');

            // Update config with new range starting from 0
            config.headers['Content-Range'] = `bytes 0-${fileBlob.size - 1}/${fileBlob.size}`;

            // Retry the request from beginning
            return makeRequest(retries - 1, delay * 2);
          }

          reject(callbackReject);
        }
      };

      makeRequest();
    });
  }
}
