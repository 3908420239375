import { log } from 'console';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import AccountService from './account/account.service';
import { CmRoleType } from './shared/model/enumerations/cm-role-type.model';
@Component({
  components: {},
})
export default class AppRouteManagemet extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  public visibleLogoBti = false;
  public visibleRegister = false;
  public visibleNavbar = false;
  public visibleSidebar = false;
  public visibleTab = false;
  public isActiveMenuFooter = 'Home';

  // =================  VARIABLES =================
  // ================= START DEFAULT FUNCTION =================
  public created() {
    this.$watch('$route', function (routeTo, routeFromo) {
      this.visibleLogoBti = false;
      this.visibleRegister = false;
      this.visibleNavbar = false;
      this.visibleSidebar = false;
      // this.visibleTab = false;

      // alert(1);

      this.isActiveMenuFooter = routeTo.name;

      if (routeTo.path.includes('/p-cp')) {
        this.$store.commit('setShowLogoBti', 'BTI');
        this.$store.commit('setIsShowRegister', true);
        this.$store.commit('setIsControlPanel', true);
        // this.visibleLogoBti = s.visibleRegister = true;
        // alert(routeTo.path);true;
        // thi
      } else {
        // alert(1);
        this.$store.commit('setIsControlPanel', false);
        this.$store.commit('setShowLogoBti', 'CB');
        this.$store.commit('setIsShowRegister', false);

        this.visibleLogoBti = false;
        this.visibleRegister = false;
      }
      if (routeTo.path.includes('/p-chat-container')) {
        this.$store.commit('setShowTab', false);
      } else {
        this.$store.commit('setShowTab', true);
      }
    });
    // this.$store.watch(
    //   () => this.$store.getters.currentUser,
    //   () => {
    //     this.initRole();
    //   }
    // );

    // this.$store.watch(() => store);
  }

  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
