// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import VueLazyload from 'vue-lazyload';
import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import SocketService from './admin/socket/socket.service';

import GatewayService from '@/admin/gateway/gateway.service';
import PChtService from './modules/services/cht.service';
import PChtAttachmentOneService from './modules/services/cht-attachment-one.service';
import PCbUserSocialMediaService from './modules/services/cb-user-social-media';
import PCbVwUser4CrmService from './modules/services/cb-vw-user-4-crm.service';
import GlobalService from './modules/services/global.service';
//firebase, set before accountservicd
import FirebaseConfig from './firebase';
import PCrmMainService from './modules/services/crm-main.component';
import PCbMainService from '@/modules/services/cb-main.component';
import PCbVwUserData4CrmService from '@/modules/services/cb-vw-user-data-4-crm.service';
import PCmVwSopInputedAggService from './modules/services/cm-vw-sop-inputed-agg.service';
import PCbAttachmentRestrictService from './modules/services/cb-attachment-restrict.service';
import PCmSopInputedAggService from './modules/services/cm-sop-inputed-agg.services';
import PCmAttachmentAuthService from './modules/services/cm-attachment-auth.service';
import PCbVwPawData4CrmService from './modules/services/cb-vw-paw-data-4-crm.service';
import PCbTextNoAuthService from './modules/services/cb-text-no-auth.service';
import PCmSopInputedAggAttService from './modules/services/cm-sop-inputed-agg-att.service';
import PCbAttachmentNoAuthService from './modules/services/cb-attachment-no-auth.service';
import PCbPawDataVerification4CrmService from './modules/services/cb-paw-data-verification-4-crm.service';
import PCmTktTextService from './modules/services/cm-tkt-text.service';
import PCmTktAttService from './modules/services/cm-tkt-att.service';
import PCmTextAuthService from './modules/services/cm-text-auth.service';
import PCbUserCrtVerificationService from './modules/services/cb-user-crt-verification.service';
import PCmVwTktService from './modules/services/cm-vw-tkt.service';
import PCmTktService from './modules/services/cm-tkt.service';
import PCmVwUserService from './modules/services/cm-vw-user.service';
import PCbVwPawOrderService from './modules/services/cb-vw-paw-order.service';
import PCmVwSopService from './modules/services/cm-vw-sop.service';
import PCmVwDisputeScoreService from './modules/services/cm-vw-dispute-score.service';
import PCmVwTktStatusGeneralService from './modules/services/cm-vw-tkt-status-general.service';
import PCmEmailService from './modules/services/cm-email.service';
import PCmEmailAttachmentService from './modules/services/cm-email-attachment.service';
import PCmVwEmailTktService from './modules/services/cm-vw-email-tkt.service';
import PCbPawPortfolioDocService from './modules/services/cb-paw-portfolio-doc.service';
import 'swiper/swiper-bundle.min.css';

// CHAT
import ChtMessageDeleteService from './modules/services/cht-message-delete.service';
import ChtReactionService from './modules/services/cht-reaction.service';
import ChtStarService from './modules/services/cht-star.service';
import ChtFileService from './modules/services/cht-file.service';
import PCbUserInfoService from './modules/services/cb-user-info.service';
import PCbLovService from './modules/services/cb-lov.service';
import { VEmojiPicker } from 'v-emoji-picker';
// END CHAT
// VUEPER ======================================================
import { VueperSlide, VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

import PCbVwMedalService from './modules/services/cb-vw-medal.service';
import PCbVwPawMetaValueService from './modules/services/cb-vw-paw-meta-value.service';
import PCbVwPawDetailAttService from './modules/services/cb-vw-paw-detail-att.service';
import PCbVwPawCrtService from './modules/services/cb-vw-paw-crt.service';
import PCbVwUserService from './modules/services/cb-vw-user.service';
import PCbVwPartnerBalanceService from './modules/services/cb-vw-partner-balance.service';
import PCbVwBuyerBalanceService from './modules/services/cb-vw-buyer-balance.service';
import PCbVwUserReviewService from './modules/services/cb-vw-review-user.service';
import PCbVwAuctionProjectService from './modules/services/cb-vw-auction-project.service';
import PCbVwOrderStatisticService from './modules/services/cb-vw-order-statistics.service';
import PCbVwOrderPercentageStatisticService from './modules/services/cb-vw-order-percentage-statistics.service';
import PCbVwBillingHistoryService from './modules/services/cb-vw-billing-history.service';
import PCbBankAccountService from './modules/services/cb-bank-accounts.service';
import PCbUserWarningService from './modules/services/cb-user-warning.service';
import PCbTakedownService from './modules/services/cb-takedown.service';
import PCbVwPawService from './modules/services/cb-vw-paw.service';
import PCbVwUserManagementHistoryService from './modules/services/cb-vw-user-management-history.service';
import PCbVwPaw4CrmService from './modules/services/cb-vw-paw-4-crm.service';
import PCbVwPawPortfolioService from './modules/services/cb-vw-paw-portfolio.service';
import PCbVwUserCrtService from './modules/services/cb-vw-user-crt.service';
import PCbPawOrderTrackService from './modules/services/cb-paw-order-track-service.service';
import PCbPawOrderFileService from './modules/services/cb-paw-order-file.service';
import PCbPawOrderMilestoneService from './modules/services/cb-paw-order-milistones.service';
import PCbVwPawOrderRequirementService from './modules/services/cb-vw-paw-order-requirement.service';
import PCbTextRestrictService from './modules/services/cb-text-restrict.service';
import PCmTktResolutionService from './modules/services/cm-tkt-resolution.service';
import PCmTktTagService from './modules/services/cm-tkt-tag.service';
import PCmVwTktResolutionService from './modules/services/cm-vw-tkt-resolution.service';
import PCbHelpCenterFeedbackService from './modules/services/cb-help-center-feedback.service';
import RptKpiMetaTableService from './modules/services/rpt-kpi-meta-table.service';
import PCmUserInfoService from './modules/services/cm-user-info.service';
import PNtVwUserNotifService from './modules/services/nt-vw-user-notif.service';
import PCmVwDashboardSummaryService from './modules/services/cm-vw-dashboard-summary.service';
import PCmCreateAccount from './modules/services/cm-create-account.service';
import PCmLogService from './modules/services/cm-log.service';
import PCbUserEducationService from './modules/services/cb-user-education.service';
import PCbUserCrtService from './modules/services/cb-user-crt.service';
import PNtUserNotifService from './modules/services/nt-user-notif.service';
import PCbVwSvcCatService from './modules/services/cb-vw-svc-cat.service';
import PCbVwBankAccountService from './modules/services/cb-vw-bank-account.service';
import ChtAggConversationMemberService from './modules/services/cht-agg-conversation-member.service';
import PCbVwSvcService from './modules/services/cb-vw-svc.service';
import PCmVwAgentMatrixService from './modules/services/cm-vw-agent-matrix.service';
import PCmVwAgentLastActivityService from './modules/services/cm-vw-agent-last-activity.service';
import PCmVwBestAgentService from './modules/services/cm-vw-best-agent.service';
import PCmVwTktChatNotAcceptedService from './modules/services/cm-vw-tkt-chat-not-accepted.service';
import PCbVwUserStatusHistoryService from './modules/services/cb-vw-user-status-history.service';
import PPayService from './modules/services/pay.service';
import PCbVwUserBalanceSummaryService from './modules/services/cb-vw-user-balance-summary.service';
import PCbVwPawPkgAggService from './modules/services/cb-vw-paw-pkg-agg.service';
import ContentService from './modules/services/content.service';
import PCbVwUserPartnerLevelService from './modules/services/cb-vw-user-partner-level.service';
import PCbVwUserSummaryService from './modules/services/cb-vw-user-summary.service';
import PWfVwWorkflowService from './modules/services/wf-vw-workflow.service';
import RptMetaTablesService from './modules/services/rpt-meta-tables.service';
import PCbVwPawOrderReviewService from './modules/services/cb-vw-paw-order-review.service';
import PCbEducationService from './modules/services/cb-education.service';
import PCbVwRegUserSummaryService from './modules/services/cb-vw-reg-user-summary.service';
import PCbUserService from './modules/services/cb-user.service';
import PCmRegionsService from './modules/services/cm-regions.service';
import PCmVwRegions from './modules/services/cm-vw-regions.service';
import PCmGlobalTargets from './modules/services/cm-global-targets.service';
import PCmVwGlobalTargetAssignAchievedsService from './modules/services/cm-vw-global-target-assign-achieveds.service';
import PCmTktAssignPicService from './modules/services/cm-tkt-assign-pic.service';
import ChtMessageSeenService from '@/modules/services/cht-message-seen.services';
import PCbUserDataVerificationStatusesService from './modules/services/cb-user-data-verification-statuses.service';

// VUEPER ======================================================

/* tslint:disable */

gsap.registerPlugin(ScrollTrigger);

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here
// SwiperClass.use([Pagination, Navigation, Mousewheel, Autoplay]);
// Vue.use(getAwesomeSwiper(SwiperClass));
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('v-emoji-picker', VEmojiPicker);
Vue.component('vueper-slides', VueperSlides);
Vue.component('vueper-slide', VueperSlide);
Vue.component('vueper-slide', VueperSlide);
Vue.use(VueLazyload, {
  observer: true,

  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },

  error: 'content/images/icon/Blank_Picture.png',
  loading: '',
  attempt: 1,
});
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const fcmConfig = new FirebaseConfig(store, null);
Vue.prototype.$fcm = fcmConfig.messaging;

const pCmVwUserService = new PCmVwUserService();

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router, pCmVwUserService);
const socketService = new SocketService(router, store, accountService);
const globalService = new GlobalService(store, accountService, router, socketService);
const contentService = new ContentService();

// move here to avoid authorize
setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;

    if (status === 401) {
      //open login modal when click chat on detail paw
      if (url.endsWith('api/cb-paw-pre-orders')) {
        sessionStorage.setItem('requested-url', router.currentRoute.path); // to handle if not authorize when chat to user
      }

      // Store logged out state.
      if (url.endsWith('is-session-live')) {
      }

      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        // window.location.reload();
        // return;
      }
    }

    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    //clear when server down
    const status = error.status || error.response.status;
    if (status === 504) {
      // if (url.endsWith('/api/cb-user-infos/current')) {
      //   store.commit('logout');
      // }
    }
    console.log('Server error!');
    return Promise.reject(error);
  },
  globalService,
);

let isFirstLoginForceRetrieveAccountToCheckSessionAccount = true;
router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities, isFirstLoginForceRetrieveAccountToCheckSessionAccount).then(value => {
      isFirstLoginForceRetrieveAccountToCheckSessionAccount = false;
      if (!value) {
        sessionStorage.setItem('before-requested-url', from.fullPath);
        sessionStorage.setItem('requested-url', to.fullPath);

        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    accountService
      .isLogin(isFirstLoginForceRetrieveAccountToCheckSessionAccount)
      .then(value => {
        isFirstLoginForceRetrieveAccountToCheckSessionAccount = false;
        if (value && !sessionStorage.getItem('requested-url')) {
          next('/p-cp');
        } else {
          next();
        }
      })
      .catch(() => {
        next();
      });
  }
});

Vue.use(VueLazyload, {
  observer: true,

  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },

  error: '../content/images/loading_paw_1.png',
  loading: '../content/images/loading_paw.gif',
  attempt: 1,
});

Vue.prototype.$env = process.env;

/* tslint:disable */
const vueObj = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,

    contentService: () => contentService,
    gatewayService: () => new GatewayService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),
    pChtService: () => new PChtService(),
    pCrmMainService: () => new PCrmMainService(),
    pChtAttachmentOneService: () => new PChtAttachmentOneService(),

    pCbMainService: () => new PCbMainService(),
    pCbUserSocialMediaService: () => new PCbUserSocialMediaService(),
    pCbVwUser4CrmService: () => new PCbVwUser4CrmService(),
    translationService: () => translationService,
    socketService: () => socketService,

    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
    globalService: () => globalService,
    pCbVwUserData4CrmService: () => new PCbVwUserData4CrmService(),
    pCmVwSopInputedAggService: () => new PCmVwSopInputedAggService(),
    pCbAttachmentRestrictService: () => new PCbAttachmentRestrictService(),
    pCmSopInputedAggService: () => new PCmSopInputedAggService(),
    pCmAttachmentAuthService: () => new PCmAttachmentAuthService(),
    pCbVwPawData4CrmService: () => new PCbVwPawData4CrmService(),
    pCbTextNoAuthService: () => new PCbTextNoAuthService(),
    pCmSopInputedAggAttService: () => new PCmSopInputedAggAttService(),
    pCbAttachmentNoAuthService: () => new PCbAttachmentNoAuthService(),
    pCbPawDataVerification4CrmService: () => new PCbPawDataVerification4CrmService(),
    pCmTktTextService: () => new PCmTktTextService(),
    pCmTktAttService: () => new PCmTktAttService(),
    pCmTextAuthService: () => new PCmTextAuthService(),
    pCbUserCrtVerificationService: () => new PCbUserCrtVerificationService(),
    // pCbVwUser: () => new PCbVwUserService(),
    pCmVwTktService: () => new PCmVwTktService(),
    pCbUserService: () => new PCbUserService(),
    pCmTktService: () => new PCmTktService(),
    pCbVwPawOrderService: () => new PCbVwPawOrderService(),
    pCbVwPawOrderReviewService: () => new PCbVwPawOrderReviewService(),
    pCmVwSopService: () => new PCmVwSopService(),
    pCbVwPartnerBalanceService: () => new PCbVwPartnerBalanceService(),
    pCbVwBuyerBalanceService: () => new PCbVwBuyerBalanceService(),
    pCbVwUserReviewService: () => new PCbVwUserReviewService(),
    pCmVwDisputeScoreService: () => new PCmVwDisputeScoreService(),
    pCmVwTktStatusGeneralService: () => new PCmVwTktStatusGeneralService(),
    pCmEmailService: () => new PCmEmailService(),
    pCmEmailAttachmentService: () => new PCmEmailAttachmentService(),
    pCbVwAuctionProjectService: () => new PCbVwAuctionProjectService(),
    pCbVwOrderStatisticService: () => new PCbVwOrderStatisticService(),
    pCbVwOrderPercentageStatisticService: () => new PCbVwOrderPercentageStatisticService(),
    pCbVwBillingHistoryService: () => new PCbVwBillingHistoryService(),
    pCbBankAccountService: () => new PCbBankAccountService(),
    pCmVwEmailTktService: () => new PCmVwEmailTktService(),
    pCbUserWarningService: () => new PCbUserWarningService(),
    pCbTakedownService: () => new PCbTakedownService(),
    pCmRegionsService: () => new PCmRegionsService(),
    pCbVwPawService: () => new PCbVwPawService(),
    pCmVwRegions: () => new PCmVwRegions(),
    pCmGlobalTargets: () => new PCmGlobalTargets(),
    pCmVwGlobalTargetAssignAchievedsService: () => new PCmVwGlobalTargetAssignAchievedsService(),
    pCmTktAssignPicService: () => new PCmTktAssignPicService(),
    pCbPawPortfolioDocService: () => new PCbPawPortfolioDocService(),
    pCbVwUserManagementHistoryService: () => new PCbVwUserManagementHistoryService(),
    pCbVwPaw4CrmService: () => new PCbVwPaw4CrmService(),
    pCbVwPawPortfolioService: () => new PCbVwPawPortfolioService(),
    pCbVwUserCrtService: () => new PCbVwUserCrtService(),
    pCbPawOrderTrackService: () => new PCbPawOrderTrackService(),
    pCbPawOrderFileService: () => new PCbPawOrderFileService(),
    pCbPawOrderMilestoneService: () => new PCbPawOrderMilestoneService(),
    pCbVwPawOrderRequirementService: () => new PCbVwPawOrderRequirementService(),
    pCbTextRestrictService: () => new PCbTextRestrictService(),
    pCmTktResolutionService: () => new PCmTktResolutionService(),
    pCmTktTagService: () => new PCmTktTagService(),
    pCbVwPawPkgAggService: () => new PCbVwPawPkgAggService(),

    pCmVwTktResolutionService: () => new PCmVwTktResolutionService(),
    pCbHelpCenterFeedbackService: () => new PCbHelpCenterFeedbackService(),
    pCbUserDataVerificationStatusesService: () => new PCbUserDataVerificationStatusesService(),
    pCmUserInfoService: () => new PCmUserInfoService(),
    pCbUserEducationService: () => new PCbUserEducationService(),
    pCbEducationService: () => new PCbEducationService(),
    pNtVwUserNotifService: () => new PNtVwUserNotifService(),
    pCmVwDashboardSummaryService: () => new PCmVwDashboardSummaryService(),
    pCmCreateAccount: () => new PCmCreateAccount(),
    pCmVwUserService: () => new PCmVwUserService(),
    pCmLogService: () => new PCmLogService(),
    pCbUserCrtService: () => new PCbUserCrtService(),
    pNtUserNotifService: () => new PNtUserNotifService(),
    pCbVwSvcCatService: () => new PCbVwSvcCatService(),
    pCbVwSvcService: () => new PCbVwSvcService(),
    pCbVwBankAccountService: () => new PCbVwBankAccountService(),
    pCmVwAgentMatrixService: () => new PCmVwAgentMatrixService(),
    pCmVwAgentLastActivityService: () => new PCmVwAgentLastActivityService(),
    pCmVwBestAgentService: () => new PCmVwBestAgentService(),
    // CHAT
    chtMessageDeleteService: () => new ChtMessageDeleteService(),
    chtReactionService: () => new ChtReactionService(),
    chtStarService: () => new ChtStarService(),
    chtFileService: () => new ChtFileService(),
    pCbUserInfoService: () => new PCbUserInfoService(),
    pCbVwUserService: () => new PCbVwUserService(),
    pCbLovService: () => new PCbLovService(),
    pCbVwMedalService: () => new PCbVwMedalService(),
    pCbVwPawMetaValueService: () => new PCbVwPawMetaValueService(),
    pCbVwPawDetailAttService: () => new PCbVwPawDetailAttService(),
    pCbVwPawCrtService: () => new PCbVwPawCrtService(),
    rptKpiMetaTableService: () => new RptKpiMetaTableService(),
    chtAggConversationMemberService: () => new ChtAggConversationMemberService(),
    pCmVwTktChatNotAcceptedService: () => new PCmVwTktChatNotAcceptedService(),
    pCbVwUserStatusHistoryService: () => new PCbVwUserStatusHistoryService(),
    pPayService: () => new PPayService(),
    pRptMetaTablesService: () => new RptMetaTablesService(),
    pCbVwUserBalanceSummaryService: () => new PCbVwUserBalanceSummaryService(),
    pCbVwRegUserSummaryService: () => new PCbVwRegUserSummaryService(),
    pCbVwUserPartnerLevelService: () => new PCbVwUserPartnerLevelService(),
    pCbVwUserSummaryService: () => new PCbVwUserSummaryService(),
    pCbVWMedalService: () => new PCbVwMedalService(),
    pWfVwWorkflowService: () => new PWfVwWorkflowService(),
    pChtMessageSeen: () => new ChtMessageSeenService(),
  },
  i18n,
  store,
});
