import axios from 'axios';

import { ChtFile, IChtFile } from '@/shared/model/cht-file.model';

const baseApiUrl = 'services/btichat/api/cht-files';

export default class ChtFileService {
  public create(idTokenOrUserIdLa: string, entity: IChtFile, type: string, fileBlob: Blob, callBack: Function): Promise<IChtFile> {
    return new Promise<IChtFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          var result: ChtFile = res.data;
          resolve(this.upload(result, result.uploadLink, idTokenOrUserIdLa, type, fileBlob, callBack));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create4La(idTokenOrUserIdLa: string, entity: IChtFile, type: string, fileBlob: Blob, callBack: Function): Promise<IChtFile> {
    return new Promise<IChtFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/la/${idTokenOrUserIdLa}`, entity)
        .then(res => {
          var result: ChtFile = res.data;
          resolve(this.upload(result, result.uploadLink, idTokenOrUserIdLa, type, fileBlob, callBack));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public upload(
    result: IChtFile,
    uploadLink: string,
    idTokenOrUserIdLa: string,
    type: string,
    fileBlob: Blob,
    callBack: Function
  ): Promise<any> {
    var config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(signUrlRes => {
          axios.post(`${baseApiUrl}/publish-message/${idTokenOrUserIdLa}`, result);

          resolve(result);
        })
        .catch(signUrlErr => {
          reject(false);
        });
    });
  }

  // public createUploadLink(id): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     axios
  //       .post(`${baseApiUrl}/upload-link/${id}`)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public createDownloadLink(chtFileId, token): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/download-link?id.equals=` + chtFileId + `&token.equals=` + token)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
