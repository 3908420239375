import { Module } from 'vuex';

export const chartsStore: Module<any, any> = {
  state: {
    colors: {
      twoColorBlueDarkest: ['#FF4E4E', '#0030DC'],
      twoColorBlueDark: ['#18FF22', '#FF0FBC'],
      twoColorBlueLight: ['#E38A21', '#78BEFF'],
      threeColorLine: ['#003399', '#AE1010', '#546B8D'],
      fiveColorLineTicketChannel: ['#546B8D', '#7DC5EA', '#FF7B31', '#E57B7B', '#796AD0'],
      fourColorLinePRiority: ['#6DD6CC', '#FF9900', '#796AD0', '#FF2E00'],
      twoColorRedOrange: ['#FF9900', ' #FF2E00'],
      threeColorBlueDarkestGreen: ['#15638A', '#47BC2A', '#6688B0'],
      sixColorUserByAge: ['#6DD6CC', '#8FE088', '#796AD0', '#E57B7B', '#FF7B31', '#FFCE31'],
      fiveColorUserByCity: ['#6DD6CC', '#8FE088', '#796AD0', '#E57B7B', '#FF7B31'],
      twoColorUserByActivity: ['#47BC2A', '#FF2E00'],
      twoColorUserByAccountStatus: ['#008890', '#6DD6CC'],
      twoColorUserByGender: ['#15638A', '#E67B7B'],
      twoColorUserByWorkerType: ['#6859BC', '#FFCE31'],
      fiveColorFreeLancerByRank: ['#171A2A', '#FCEED2', '#F0BF66', '#B7CEDB', '#D38664'],
      fiveColorPawPopularService: ['#796AD0', '#E57B7B', '#FF7B31', '#546B8D', '#7DC5EA'],
      twoColorPawByActivity: ['#009083', '#008891'],
      twoColorPawByWorkertype: ['#FFCE31', '#6859BC'],
      fiveColorPawByServiceFieldWorker: ['#FF9900', '#4FC0C3', '#9887FB', '#E57B7B', '#5F5F5F'],
      threeColorAgentByType: ['#FFCE31', '#FF7B31', '#15638A'],
      twoColorAgentByActivity: ['#47BC2A', '#FF2E00'],
      twoColorAgentResponse: ['#FFCE31', '#FF7B31'],
      colorPerformanceQuality: ['#81FBB8', '#D8DDF7'],
      colorPerformanceTimeline: ['#0396FF', '#D8DDF7'],
      colorPerformancePersonality: ['#F9A61B', '#D8DDF7'],
      twoColorEaningStatic: ['#AE1010', '#003399'],
      sixColorTotalOrder: ['#6DD6CC', '#9B9C9C', '#FFB302', '#47BC2A', '#D14643', '#D9D9D9'],
    },
  },
  getters: {
    colors: state => state.colors,
  },
  actions: {},
};
