import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IChtAggConversationMember } from '@/shared/model/cht-agg-conversation-member.model';

const baseApiUrl = 'services/btichat/api/cht-agg-conversation-members';

export default class ChtAggConversationMemberService {
  public find(id: number): Promise<IChtAggConversationMember> {
    return new Promise<IChtAggConversationMember>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiUrl +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IChtAggConversationMember): Promise<IChtAggConversationMember> {
    return new Promise<IChtAggConversationMember>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IChtAggConversationMember): Promise<IChtAggConversationMember> {
    return new Promise<IChtAggConversationMember>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IChtAggConversationMember): Promise<IChtAggConversationMember> {
    return new Promise<IChtAggConversationMember>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public isAdmin(conversationId?: number): Promise<boolean> {
    if (!conversationId) return;
    return new Promise<boolean>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/is-admin?chtConversationId.equals=${conversationId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public makeMemberAdmin(entity: IChtAggConversationMember): Promise<IChtAggConversationMember> {
    return new Promise<IChtAggConversationMember>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/make-member-admin/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public dismissAdmin(entity: IChtAggConversationMember): Promise<IChtAggConversationMember> {
    return new Promise<IChtAggConversationMember>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/make-member-dismiss-admin/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteMember(id: number, conversationId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}/${conversationId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public quitGroup(conversationId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/quit-group/${conversationId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
