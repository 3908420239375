import axios from "axios";

const baseApiUrl = 'services/crmcorems/api/tkt-assign-pic'
export default class PCmTktAssignPicService {
  public update(data: any, email: string) {
    return new Promise((resolve, reject) => {
      axios.patch(`${baseApiUrl}/${data.id}/${email}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(new Error(error))
        })
    })
  }

}