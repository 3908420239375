import Vue from 'vue';

export function initFilterGeneral() {
  Vue.filter('inputNoSpecialChar', (value: string) => {
    if (value) {
      return value.replace(/[^0-9A-Za-z_\s]/gi, '');
    }
    return '';
  });
  Vue.filter('regexLetterAndSpaceOnly', (value: string) => {
    if (value) {
      return value.replace(/[^A-Za-z ]/gi, '');
    }
    return '';
  });

  Vue.filter('regexLetterAndNumberOnly', (value: string) => {
    if (value) {
      return value.replace(/[^0-9A-Za-z]/gi, '');
    }
    return '';
  });

  Vue.filter('regexNumberOnly', (value: string) => {
    if (value && !Number.isNaN(Number(value))) {
      value = value.replace(/[^\d]+/g, '');
      return Number(value);
    } else if (value && Number.isNaN(Number(value))) {
      return Number(value.replace(/[^\d]+/g, ''));
    }
    return null;
  });

  Vue.filter('regexFormatedNumber', (value: string) => {
    if (value && !Number.isNaN(Number(value))) {
      value = value.replace(/[^\d]+/g, '');
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else if (value && Number.isNaN(Number(value))) {
      value = value.replace(/[^\d]+/g, '');
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return null;
  });

  Vue.filter('extractNumber', (value: string) => {
    if (value) {
      value = value.replace(/[^\d]+/g, '');
      return Number(value);
    }
    return null;
  });

  // make filter for format to precentage
  Vue.filter('percentage', (value: number) => {
    if (value) {
      return `${value}%`;
    }
    return '';
  });

  //filter for format to pascal case
  Vue.filter('pascalCase', (value: string) => {
    if (value) {
      return value.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
    }
    return '';
  });

  Vue.filter('regexPhoneNumber', (value: string) => {
    if (value && !Number.isNaN(Number(value))) {
      value = value.replace(/[^\d]+/g, '');
      if (value[0].toString() == '0') {
        value = value.slice(1);
      }
      return value;
    } else if (value && Number.isNaN(Number(value))) {
      return value.replace(/[^\d]+/g, '');
    }
    return null;
  });

  Vue.filter('regexLetterNumberAndValidChar', (value: string) => {
    if (value) {
      value = value.replace(/[^0-9A-Za-z \.\,\;\:\-\_\?\!()[\]"\/'`‘’'&“"”]/gi, '');
      value = value.replace(/::/gi, ':');
      value = value.replace(/:::/gi, ':');
      value = value.replace(/::/gi, ':');
      value = value.replace(/\|\|/gi, '');
      return value;
    }
    return '';
  });
}
