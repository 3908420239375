export enum Authority {
  ROLE_CRM_AGENT = 'ROLE_CRM_AGENT',
  ROLE_CRM_CUSTOMER_SUPPORT = 'ROLE_CRM_CUSTOMER_SUPPORT',
  ROLE_CRM_FINANCE_TEAM = 'ROLE_CRM_FINANCE_TEAM',
  ROLE_CRM_MARKETING = 'ROLE_CRM_MARKETING',
  ROLE_CRM_MARKETING_MANAGER = 'ROLE_CRM_MARKETING_MANAGER',
  ROLE_CRM_IT_SUPPORT = 'ROLE_CRM_IT_SUPPORT',
  ROLE_CRM_MANAGERIAL = 'ROLE_CRM_MANAGERIAL',
  ROLE_CRM_SUPER_AGENT= 'ROLE_CRM_SUPER_AGENT',
  ROLE_CRM_USER = 'ROLE_CRM_USER',
  ROLE_CRM_VERIFICATION_AGENT= 'ROLE_CRM_VERIFICATION_AGENT',
  CRM_USER='CRM_USER',
  USER='USER',
  ADMIN='ADMIN'
}
