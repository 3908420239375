import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICbUserInfo } from '@/shared/model/cb-user-info.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-infos';
const baseApiUrlTwo = 'services/cbuserms/api/users';

export default class PCbUserInfoService {
  public getCurrent(): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/current`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findUsernameExist(username: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/is-username-exist/${username}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findEmailExist(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlTwo}/is-exist/${email}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findPhoneExist(phoneCode: string, phone: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlTwo}/is-exist-phone/${phoneCode}/${phone}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: string): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbUserInfo): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbUserInfo): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public updateRegisterFreelancer(entity: ICbUserInfo): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/register-freelancer/current`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdateByLogin(entity: ICbUserInfo): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByLogin(): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public partialUpdate(partial: any): Promise<ICbUserInfo> {
  //   return new Promise<ICbUserInfo>((resolve, reject) => {
  //     axios
  //       .patch(`${baseApiUrl}/${partial.id}`, partial)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }
}
