var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("ribbon"),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.visibleMenuBar ? "d-block" : "d-none",
          attrs: { id: "app-header" },
        },
        [_c("jhi-navbar")],
        1
      ),
      _vm._v(" "),
      _c("div", {}, [_c("router-view")], 1),
      _vm._v(" "),
      _c(
        "b-toast",
        {
          ref: "toastProgressUpload",
          attrs: {
            "body-class": "bg-white border-radius-26",
            "header-class": "d-none",
            solid: "",
            "no-auto-hide": "",
          },
        },
        [
          _c("c-progress-upload", {
            attrs: { progressUploads: _vm.progressUploads },
            on: { closeProgressUploadMini: _vm.closeProgressUploadMini },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }