import axios from 'axios';

const baseApiUrl = 'services/btichartreport/api/rpt-meta-tables/download-pdf/CB_APP/PAYMENT_RECEIPT_ORDER_PAW';
export default class RptMetaTablesService {
  public find(additionalId: string): void {
    axios
      .get(`${baseApiUrl}/${additionalId}::ID`, { responseType: 'arraybuffer' })
      .then(res => {
        let headerLine = res.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.target = '_blank';
        link.click();
      })
      .catch(err => {});
  }
}
