import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';
import CmVwUserService from '../../app/modules/services/cm-vw-user.service';
import { Authority } from '@/shared/security/authority';

export default class AccountService {
  constructor(
    private store: Store<any>,
    private translationService: TranslationService,
    private cookie: any,
    private router: VueRouter,
    private cmVwUserService: CmVwUserService,
  ) {
    this.init();
  }

  public init(): void {
    this.retrieveProfiles();
  }

  public retrieveProfiles(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get<any>('management/info')
        .then(res => {
          if (res.data && res.data.activeProfiles) {
            this.store.commit('setRibbonOnProfiles', res.data['display-ribbon-on-profiles']);
            this.store.commit('setActiveProfiles', res.data['activeProfiles']);
          }
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }

  public retrieveAccount(): Promise<boolean> {
    //test adehuh
    let token = localStorage.getItem('crm-jhi-authenticationToken') || sessionStorage.getItem('crm-jhi-authenticationToken');
    if (token) token = 'Bearer ' + token;
    //end test adehuh
    return new Promise(resolve => {
      axios
        .get<any>('api/account', { headers: { Authorization: token } })
        .then(async response => {
          // this.store.commit('authenticate');
          const account = response.data;
          if (account) {
            this.store.commit('logon', true);

            this.store.commit('authenticated', account);
            // localStorage.setItem('crm-jhi-id-token', account.idToken);// move to keycloak
            this.store.commit('setIsShowCSolutionArticleSearch', false);
            //handle looping no role
            let isHaveAccess = true;
            await this.checkAuthorities([Authority.ROLE_CRM_USER]).then(value => {
              if (!value) {
                this.logoutStore();
                isHaveAccess = false;
                alert('dont have access');

                resolve(false);
              }
            });
            if (!isHaveAccess) return;
            //handle looping no role

            if (sessionStorage.getItem('requested-url')) {
              this.router.replace(sessionStorage.getItem('requested-url'));
              sessionStorage.removeItem('requested-url');
            }

            this.store.commit('setCaseAgent', false);
            this.store.commit('setCustomerSupport', false);
            this.store.commit('setFinanceTeam', false);
            this.store.commit('setItSupport', false);
            this.store.commit('setManagerial', false);
            this.store.commit('setVerificationAgent', false);
            this.store.commit('setSuperAgent', false);
            this.store.commit('setMarketingTeam', false);
            this.store.commit('setDirector', true);

            await this.checkAuthorities([Authority.ROLE_CRM_AGENT]).then(value => {
              this.store.commit('setCaseAgent', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_CUSTOMER_SUPPORT]).then(value => {
              this.store.commit('setCustomerSupport', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_FINANCE_TEAM]).then(value => {
              this.store.commit('setFinanceTeam', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_MARKETING]).then(value => {
              this.store.commit('setMarketingTeam', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_MARKETING_MANAGER]).then(value => {
              this.store.commit('setMarketingManager', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_IT_SUPPORT]).then(value => {
              this.store.commit('setItSupport', value);
            });

            await this.checkAuthorities([Authority.ROLE_CRM_MANAGERIAL]).then(value => {
              this.store.commit('setManagerial', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_SUPER_AGENT]).then(value => {
              this.store.commit('setSuperAgent', value);
            });
            await this.checkAuthorities([Authority.ROLE_CRM_VERIFICATION_AGENT]).then(value => {
              this.store.commit('setVerificationAgent', value);
            });
            this.retrieveCurrentUser();
          } else {
            this.store.commit('logout');
            if (this.router.currentRoute.path !== '/') {
              this.router.push('/');
            }
            sessionStorage.removeItem('requested-url');
          }

          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .catch(err => {
          this.logoutStore();

          resolve(false);
        });
    });
  }

  public hasAnyAuthorityAndCheckAuth(authorities: any, isFirstLoginForceRetrieveAccountToCheckSessionAccount): Promise<boolean> {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    if (!this.authenticated || !this.userAuthorities || isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      if (this.isCallRetrieveAccount(isFirstLoginForceRetrieveAccountToCheckSessionAccount)) {
        return this.retrieveAccount().then(resp => {
          if (resp) {
            return this.checkAuthorities(authorities);
          }
          return Promise.resolve(false);
        });
      }
      return Promise.resolve(false);
    }

    return this.checkAuthorities(authorities);
  }

  public isLogin(isFirstLoginForceRetrieveAccountToCheckSessionAccount): Promise<boolean> {
    if (!this.authenticated || !this.userAuthorities || isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      if (this.isCallRetrieveAccount(isFirstLoginForceRetrieveAccountToCheckSessionAccount)) {
        return this.retrieveAccount();
      }
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  private isCallRetrieveAccount(isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
    // this.cookie.get('XSRF-TOKEN') always filled
    const token =
      localStorage.getItem('crm-jhi-authenticationToken') ||
      sessionStorage.getItem('crm-jhi-authenticationToken') ||
      this.cookie.get('XSRF-TOKEN') ||
      this.cookie.get('JSESSIONID');
    if ((!this.store.getters.account && !this.store.getters.logon && token) || isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      return true;
    } else {
      return false;
    }
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get userAuthorities(): any {
    return this.store.getters.account?.authorities;
  }

  public retrieveCurrentUser() {
    this.cmVwUserService
      .retrieveByLogin()
      .then(res => {
        this.store.commit('currentUser', res.data[0]);
      })
      .catch(err => {
        console.log('ERROR ==> ', err);
      });
  }

  private checkAuthorities(authorities: any): Promise<boolean> {
    if (this.userAuthorities) {
      for (const authority of authorities) {
        if (this.userAuthorities.includes(authority)) {
          return Promise.resolve(true);
        }
      }
    }
    return Promise.resolve(false);
  }

  public async logout() {
    axios.post('api/logout').then(
      response => {
        window.location.href = response.data.logoutUrl;
        this.logoutStore();

        // if (response && response.data && response.data.logoutUrl) {
        //   //alert(response.data.logoutUrl)
        //   //window.location.href = response.data.logoutUrl;
        //   // https://oid.bti.id/auth/realms/bti/protocol/openid-connect/logout?redirect_uri=null
        //   if (response.data.logoutUrl.includes('null')) {
        //     response.data.logoutUrl = response.data.logoutUrl.replace('null', window.location.origin);
        //     window.location.href = response.data.logoutUrl;
        //   } else {
        //     window.location.href = response.data.logoutUrl;
        //   }
        // } else {
        //   this.router.replace({ path: '/' });
        // }
        // this.router.replace({ path: '/' });
      },
      err => {
        this.logoutStore();

        this.router.replace({ path: '/' });
      },
    );
  }

  public logoutStore() {
    this.store.commit('logout');
    this.store.commit('logoutChat');
    this.store.commit('clearTabList');
    sessionStorage.removeItem('crm-jhi-authenticationToken');
    localStorage.removeItem('crm-jhi-authenticationToken');
    localStorage.removeItem('crm-jhi-id-token');
    localStorage.removeItem('crm-jhi-access-token-4-flutter');
  }

  public retrieveUserToken(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('services/crmcorems/api/users/token')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
