import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICbVwBillingHistory } from '@/shared/model/cb-vw-billing-history.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-billing-histories';

export default class PCbVwBillingHistoryService {
  public find(id: number): Promise<ICbVwBillingHistory> {
    return new Promise<ICbVwBillingHistory>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveByParams(loginType: string, userId: string, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/crm/${loginType}/${userId}` + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbVwBillingHistory): Promise<ICbVwBillingHistory> {
    return new Promise<ICbVwBillingHistory>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbVwBillingHistory): Promise<ICbVwBillingHistory> {
    return new Promise<ICbVwBillingHistory>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbVwBillingHistory): Promise<ICbVwBillingHistory> {
    return new Promise<ICbVwBillingHistory>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadBillingHistoryPdf(method: string, id: number): Promise<any> {
    return axios
      .get(`${baseApiUrl}/download-pdf/${method}/${id}`, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {},
      })
      .then(res => {
        let headerLine = res.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        var blob = new Blob([res.data], { type: res.headers['content-type'] });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(err => {});
  }

  public retrieveCanceledInvoice(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/canceled-invoice/by-buyer-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // public downloadBillingHistoryPdf(method: string, id: number): Promise<any> {
  //   return axios
  //     .get(`${baseApiUrl}/download-pdf/${method}/${id}`, { responseType: 'arraybuffer', onDownloadProgress: progressEvent => {} })
  //     .then(res => {
  //       let headerLine = res.headers['content-disposition'];
  //       let startFileNameIndex = headerLine.indexOf('"') + 1;
  //       let endFileNameIndex = headerLine.lastIndexOf('"');
  //       let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
  //       var blob = new Blob([res.data], { type: res.headers['content-type'] });
  //       var link = document.createElement('a');
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = filename;
  //       link.click();
  //     })
  //     .catch(err => {});
  // }
}
