import { ICbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { ICbUserData } from './cb-user-data.model';

export interface ICbAttachmentRestrict {
  id?: number;
  name?: string | null;
  attContentType?: string | null;
  att?: string | null;
  attTrans?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbPawOrderFile?: ICbPawOrderFile | null;
  cbUserData?: ICbUserData | null;
}

export class CbAttachmentRestrict implements ICbAttachmentRestrict {
  constructor(
    public id?: number,
    public name?: string | null,
    public attContentType?: string | null,
    public att?: string | null,
    public attTrans?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbPawOrderFile?: ICbPawOrderFile | null,
    public cbUserData?: ICbUserData | null
  ) {
    this.isActive = this.isActive ?? false;
  }
}
