import MSalesTracking from '@/modules/main/m-sales-tracking/m-sales-tracking.vue';
import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
const PAppCp = () => import('@/modules/control-panel/app-cp.vue');
const PAppMain = () => import('@/modules/main/app-main.vue');
const PMainDashboard = () => import('@/modules/main/m-dashboard/m-dashboard.vue');
const PMainInbox = () => import('@/modules/main/m-inbox/m-inbox.vue');
const CINewEmail = () => import('@/modules/main/m-inbox/components/c-i-new-email/c-i-new-email.vue');
const PMainTicket = () => import('@/modules/main/m-ticket/m-ticket.vue');
const PMainHistory = () => import('@/modules/main/m-history/m-history.vue');
const CTicketDetails = () => import('@/modules/main/m-ticket/components/c-ticket-details/c-ticket-details.vue');
const CRequestRefundDetail = () =>
  import('@/modules/main/m-dashboard/components/d-c-finance/components/c-refund-detail/c-refund-detail.vue');
const PMainAnalytic = () => import('@/modules/main/m-analytic/m-analytic.vue');
const CBankData = () => import('@/modules/main/m-analytic/components/c-bank-data/c-bank-data.vue');
const CArchievedTicket = () => import('@/modules/main/m-analytic/components/c-archieved-ticket/c-archieved-ticket.vue');
const CArticleSolutions = () => import('@/modules/main/m-analytic/components/c-article-solutions/c-article-solutions.vue');
const PMainManageUser = () => import('@/modules/main/m-manage-user/m-manage-user.vue');
const PUserLogMarketing = () => import('@/modules/main/m-manage-user/pages/p-user-log-marketing.vue');
const PLogUser = () => import('@/modules/main/m-ticket/components/c-log-user/c-log-user.vue');
const PLogUserBalaceDetails = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-balance/c-log-user-balance-details.vue');
const PLogUserOrderDetails = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-order/c-log-user-order-details.vue');
const PLogUserSalesDetails = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-sales/c-log-user-sales-details.vue');
const CLogUserListPaw = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-list-paw/c-log-user-list-paw.vue');
const CLogUserFreezeBalance = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-freeze-balance/c-log-user-freeze-balance.vue');
const CLogUserRefundBalance = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-refund-balance/c-log-user-refund-balance.vue');
const PLogUserTotalOrder = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-total-order/c-log-user-total-order.vue');
const PLogUserReviewUser = () => import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-review/c-log-user-review.vue');
const PLogUserReportActivity = () =>
  import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-report-activity/c-log-user-report-activity.vue');
const PFreelancerVerify = () => import('@/modules/main/m-ticket/components/c-freelancer-verify/c-freelancer-verify.vue');
const PPawVerify = () => import('@/modules/main/m-ticket/components/c-paw-verify/c-paw-verify.vue');
const PMainProfile = () => import('@/modules/main/m-profile/m-profile.vue');
const PMainLeads = () => import('@/modules/main/m-leads/m-leads.vue');
const PMainFeedback = () => import('@/modules/main/m-feedback/m-feedback.vue');
const PFastRegist = () => import('@/modules/main/m-fast-regist/m-fast-regist.vue');
const PMCreateTarget = () => import('@/modules/main/m-create-target/m-create-target.vue')
const PMainRegion = () => import('@/modules/main/m-region/m-region.vue');
const PMainCreateRegion = () => import('@/modules/main/m-region/create-region/create-region.vue');
const PMDownloadRequest = () => import('@/modules/main/m-download-req/m-download-req.vue');
const CFRUserDetail = () => import('@/modules/main/m-fast-regist/components/c-user-detail/c-fr-user-detail.vue');
const CFRUpdateUser = () => import('@/modules/main/m-fast-regist/components/c-fr-update/c-fr-update.vue');
const COrderInformationDetails = () =>
  import(
    '@/modules/main/m-ticket/components/c-ticket-details/components/c-order-Information/c-order-Information-details/c-order-Information-details.vue'
  );
const PCreateUser = () => import('@/modules/main/m-manage-user/components/c-create-user/c-create-user.vue');
const PAppMainCp = () => import('@/modules/control-panel/app-main-cp/app-main-cp.vue');
const PAccount = () => import('@/modules/control-panel/app-account-cp/app-account-cp.vue');
const PEditUserAccount = () => import('@/modules/main/m-manage-user/components/c-edit-user-account/c-edit-user-account.vue');
const PSalesTracking = () => import('@/modules/main/m-sales-tracking/m-sales-tracking.vue');
const PSalesDetail = () => import('@/modules/main/m-sales-tracking/sales-detail/sales-detail.vue');
// ===== CHAT =====
const PAppChatContainer = () => import('@/modules/chat-container/AppChatContainer.vue');

const CLogUserBalance = () => import('@/modules/main/m-ticket/components/c-log-user/components/c-log-user-balance/c-log-user-balance.vue');

// ===== CHAT =====

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  // {
  //   path: '/chat-container',
  //   name: 'PAppChat',
  //   component: PAppChatContainer,
  // },

  {
    path: '/p-cp',
    component: PAppCp,
    children: [
      {
        path: '/p-cp',
        name: 'PAppCpMain',
        component: PAppMainCp,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
    ],
  },
  {
    path: '/p-main',
    component: PAppMain,
    meta: { authorities: [Authority.ROLE_CRM_USER] },
    children: [
      {
        path: '/p-main/',
        name: 'PMainDashboard',
        component: PMainDashboard,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/detail/:id',
        name: 'CRequestRefundDetail',
        component: CRequestRefundDetail,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-inbox',
        name: 'PMainInbox',
        component: PMainInbox,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-inbox/new-email/:fromPath/:email',
        name: 'CINewEmail',
        component: CINewEmail,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-main-history/',
        name: 'PMainHistory',
        component: PMainHistory,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/',
        name: 'PMainTicket',
        component: PMainTicket,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-manage-user/',
        name: 'PMainManageUser',
        component: PMainManageUser,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-manage-user/user-log-marketing/:id',
        name: 'PUserLogMarketing',
        component: PUserLogMarketing,
        meta: { authorities: [Authority.ROLE_CRM_MARKETING_MANAGER] },
      },
      {
        path: '/p-manage-user/create-user',
        name: 'PRegisterAccount',
        component: PCreateUser,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-manage-user/edit-account/:idAccount',
        name: 'PEditUserAccount',
        component: PEditUserAccount,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/:idTicket/details',
        name: 'CTicketDetails',
        component: CTicketDetails,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId',
        name: 'PLogUser',
        component: PLogUser,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId/balance',
        name: 'CLogUserBalance',
        component: CLogUserBalance,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/order',
        name: 'PLogUserOrderDetails',
        component: PLogUserOrderDetails,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/sales',
        name: 'PLogUserSalesDetails',
        component: PLogUserSalesDetails,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId/paw-user',
        name: 'CLogUserListPaw',
        component: CLogUserListPaw,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId/freeze-balance',
        name: 'CLogUserFreezeBalance',
        component: CLogUserFreezeBalance,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/refund-balance',
        name: 'CLogUserRefundBalance',
        component: CLogUserRefundBalance,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId/total-order',
        name: 'PLogUserTotalOrder',
        component: PLogUserTotalOrder,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId/review-user',
        name: 'PLogUserReviewUser',
        component: PLogUserReviewUser,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/details/log-user/:userId/report-activity',
        name: 'PLogUserReportActivity',
        component: PLogUserReportActivity,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },

      {
        path: '/p-ticket/:userId/:ticketId/p-freelancer',
        name: 'PFreelancerVerify',
        component: PFreelancerVerify,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-ticket/:pawId/:ticketId/p-paw',
        name: 'PPawVerify',
        component: PPawVerify,
        meta: { authorities: [Authority.USER, Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-paw/:pawId',
        name: 'PPawDetail',
        component: PPawVerify,
        meta: { authorities: [Authority.USER, Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-chat-container',
        name: 'PAppChat',
        component: PAppChatContainer,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-chat-container/:roomId',
        name: 'PAppChatByRoomId',
        component: PAppChatContainer,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-fast-regist',
        name: 'PFastRegist',
        component: PFastRegist,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-region',
        name: 'PMainRegion',
        component: PMainRegion,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-region/create',
        name: 'PMainCreateRegion',
        component: PMainCreateRegion,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-region/update/:regionId',
        name: 'PMainUpdateRegion',
        component: PMainCreateRegion,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-create-target',
        name: 'PCreateTarget',
        component: PMCreateTarget,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-create-target/update/:targetId',
        name: 'PUpdateTarget',
        component: PMCreateTarget,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-download-request',
        name: 'PMDownloadRequest',
        component: PMDownloadRequest,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-fast-regist/:userId',
        name: 'CFRUserDetail',
        component: CFRUserDetail,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-sales-tracking',
        name: 'PSalesTracking',
        component: PSalesTracking,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-sales-tracking/:salesId',
        name: 'PSalesDetail',
        component: PSalesDetail,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-fast-regist/:userId/update',
        name: 'CFRUpdateUser',
        component: CFRUpdateUser,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-analytic',
        name: 'PMainAnalytic',
        component: PMainAnalytic,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-analytic/bank-data',
        name: 'CBankData',
        component: CBankData,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-analytic/archieved-ticket',
        name: 'CArchievedTicket',
        component: CArchievedTicket,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-analytic/article-solutions',
        name: 'CArticleSolutions',
        component: CArticleSolutions,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },

      {
        path: '/p-profile/',
        name: 'PMainProfile',
        component: PMainProfile,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },

      {
        path: '/p-leads/',
        name: 'PMainLeads',
        component: PMainLeads,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: '/p-feedback',
        name: 'PMainFeedback',
        component: PMainFeedback,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
      {
        path: ':pawId/p-order-information-details',
        name: 'COrderInformationDetails',
        component: COrderInformationDetails,
        meta: { authorities: [Authority.ROLE_CRM_USER] },
      },
    ],
  },
];
