import axios from 'axios';

import { ICbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { ICbVwUserBalanceSummary } from '@/shared/model/cb-vw-user-balance-summary.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-balance-summaries';

export default class PCbVwUserBalanceSummaryService {
  public findByUserId(userId: string): Promise<ICbVwUserBalanceSummary> {
    return new Promise<ICbVwUserBalanceSummary>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-user-id/' + userId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
