import Vue from 'vue';
import dayjs from 'dayjs';
import { TIMEZONE_JAKARTA } from '@/constants';
import { CbInputCurrencyType } from '../model/cb-input-currency-type.model';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DATE_TIME_LONG_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_FORMAT_GMT = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)';
export const DATE_SLASH_FORMAT = 'DD/MM/YYYY';

export const DATE_ISO_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_DEFAULT = 'DD MMM YYYY HH:mm';
export const TIME_24_FORMAT = 'HH:mm';
export const DATE_MONTH = 'D MMM';
export const DATE_DEFAULT = 'DD MMM YYYY';

export function initFilters() {
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatMillis', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });
  Vue.filter('dynamicFormatDate', (value, formatStr) => {
    if (value) {
      return dayjs(value).format(formatStr);
    }
    return '';
  });
  Vue.filter('replaceAndCapital', (value, formatStr) => {
    if (value) {
      const data = value.replaceAll(formatStr, ' ').replaceAll('_', ' ');
      const data0 = data[0];
      const dataSpl = data.slice(1, data.length).toLowerCase();
      return data0 + dataSpl;
    }
  });
  Vue.filter('replaceAndCapitalAll', (value, formatStr) => {
    if (value) {
      const data = value.replaceAll(formatStr, ' ').replaceAll('_', ' ');
      const data0 = data[0];
      const dataSpl = data.slice(1, data.length).toUpperCase();
      return data0 + dataSpl;
    }
  });
  Vue.filter('operationalHourDiff', (operationHourStart, operationHourEnd) => {
    if (operationHourStart && operationHourEnd) {
      const dateStart = new Date();
      const dateEnd = new Date();
      const dateNow = new Date();
      dateStart.setHours(+new Date(operationHourStart).getHours());
      dateStart.setMinutes(+new Date(operationHourStart).getMinutes());
      dateEnd.setHours(+new Date(operationHourEnd).getHours());
      dateEnd.setMinutes(+new Date(operationHourEnd).getMinutes());

      if (dateNow.getTime() < dateStart.getTime()) {
        return `Not Available`;
      } else if (dateNow.getTime() >= dateStart.getTime() && dateNow.getTime() < dateEnd.getTime()) {
        const arrayTime: string[] = [];
        const countHour: number = dateEnd.getHours() - dateNow.getHours();
        const countMinute: number = dateEnd.getMinutes() - dateNow.getMinutes();
        if (countHour > 0) {
          arrayTime.push(countHour + ' Hour');
        }
        if (countMinute > 0) {
          arrayTime.push(countMinute + ' Minute');
        }
        return `${arrayTime.join(' and ')} to go`;
      }
      return `Not Available`;
    }
  });
  Vue.filter('elipsis', (value: string) => {
    if (value.length > 100) return value.slice(0, 100) + '...';
  });
  Vue.filter('formatRupiah', value => {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(value);
  });

  Vue.filter('formatDateTimeDefault', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_DEFAULT);
    }
    return '';
  });

  Vue.filter('formatCurrency', (value: number, currency: any) => {
    let locale: any = 'id-ID';
    if (currency) {
      for (const currency in CbInputCurrencyType) {
        if (currency.toUpperCase() == currency) {
          locale = CbInputCurrencyType[currency];
        }
      }
    }

    if (value) {
      return new Intl.NumberFormat(locale).format(value);
    }
    return '0';
  });

  Vue.filter('capitalEachSpace', value => {
    if (value) {
      value = value.toLowerCase();
      const arr = value.split('_');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      return arr.join(' ');
    }
    return '';
  });

  Vue.filter('accountNumberDisplay', value => {
    if (value) {
      return value.slice(value.length - 4);
    }
    return '';
  });
  Vue.filter('capitalFirstLetter', (value: string) => {
    if (value) {
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ');
    }
    return '';
  });

  Vue.filter('convertDateToAccordanceTimeZone', (startDate: Date, timeZoneUtc: string) => {
    const timeZone: string = timeZoneUtc?.split(' ')[1];

    if (startDate && timeZone) {
      const newDate = dayjs(startDate, { format: DATE_FORMAT_GMT }).format(DATE_ISO_STRING_FORMAT);
      const convertBasedOnJakarta = dayjs.tz(newDate, TIMEZONE_JAKARTA).format(DATE_TIME_DEFAULT);

      return `${convertBasedOnJakarta} ${timeZoneUtc}`;
    }

    return '';
  });

  Vue.filter('TRUNCATE', function (value, size, show) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size || show) {
      return value;
    }
    return value.substr(0, size) + '...';
  });

  Vue.filter('sortedRequirementsWithNewDate', function (arrayList) {
    if (!arrayList) return;
    return arrayList.sort((a, b) => dayjs(b.createdDate) - dayjs(a.createdDate));
  });

  Vue.filter('sliceCharacter', (value: string, startSlice: number) => {
    if (value && startSlice) {
      return value.slice(0, startSlice);
    }
  });

  Vue.filter('time24', value => {
    if (value) {
      const newDate = new Date(value);
      if (newDate) {
        return dayjs(value).format(TIME_24_FORMAT);
      }
    }
  });

  Vue.filter('formatDateDefault', value => {
    if (value) {
      return dayjs(value).format(DATE_DEFAULT);
    }
    return '';
  });

  Vue.filter('formatDateFromNow', value => {
    if (value) {
      return dayjs(value).fromNow();
    }
    return '-';
  });
}
