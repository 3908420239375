import { ICbUserDataVerificationStatuses } from '@/shared/model/cb-user-data-verification-statuses.model';
import axios from 'axios';

const baseApiUrl = '/services/cbuserms/api/cb-user-data-verification-statuses';

export default class PCbUserDataVerificationStatusesService {
  public create(cbUserDataVerificationStatuses: ICbUserDataVerificationStatuses): Promise<ICbUserDataVerificationStatuses> {
    return new Promise<ICbUserDataVerificationStatuses>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, cbUserDataVerificationStatuses)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
