var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.progressUploads.length > 0
    ? _c(
        "div",
        {
          staticClass: "c-progress-upload",
          attrs: { "data-cy": "progress-upload" },
        },
        [
          _c("div", { staticClass: "cursor-pointer d-flex px-1 pb-2" }, [
            _c(
              "p",
              {
                staticClass:
                  "flex-grow-1 cj-color-black-primary font-roboto font-size-12 font-weight-700 font-roboto text-ellipsis oneline",
              },
              [_vm._v("\n      Progress Upload")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex cursor-pointer",
                on: { click: _vm.closeProgressUploadMini },
              },
              [
                _c("icon", {
                  staticClass: "cj-fill-grey-secondary my-auto",
                  attrs: {
                    name: "crm-close-modal",
                    width: "20px",
                    height: "20px",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "progress-upload-mini-item px-1 pt-2 pb-1",
              attrs: { visible: _vm.isShowProgressUpload ? true : false },
            },
            _vm._l(_vm.progressUploads, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "mb-1" },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "flex-grow-1 mr-1 cj-color-black-primary font-roboto font-size-12 font-weight-700 font-roboto text-ellipsis oneline",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.title) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    item.progress == 100
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "cj-color-black-primary mr-1 font-roboto font-size-12 font-weight-500 font-roboto",
                          },
                          [_vm._v("Complete")]
                        )
                      : item.status == "Error"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "cj-color-black-primary mr-1 font-roboto font-size-12 font-weight-500 font-roboto",
                          },
                          [_vm._v("Error")]
                        )
                      : _c(
                          "p",
                          {
                            staticClass:
                              "cj-color-black-primary mr-1 font-roboto font-size-12 font-weight-500 font-roboto",
                          },
                          [_vm._v("Waiting")]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("b-progress", {
                    class: item.progress == 100 ? "actived" : "",
                    attrs: {
                      value: item.progress,
                      max: "100",
                      "show-value": "",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }