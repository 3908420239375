import { Component } from 'vue-class-component';
import {
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BProgress,
  BProgressBar,
  BPagination,
  BButton,
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavItemDropdown,
  BCollapse,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
  BAlert,
  BModal,
  VBModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BAvatar,
  BPopover,
  VBToggle,
  BTabs,
  BTab,
  BButtonGroup,
  BInputGroupAppend,
  BIconSearch,
  BIconGear,
  BIconCaretRightFill,
  BIconChevronDown,
  BIconEmojiWink,
  BIconPaperclip,
  BIconReply,
  BIconTrash,
  BIconForward,
  BIconXCircleFill,
  BFormTextarea,
  BTooltip,
  BIconLock,
  BIconCheck2,
  BIconArrowDownCircle,
  BIconClock,
  BIconArrowClockwise,
  BIconClockHistory,
  BDropdownForm,
  BToast,
} from 'bootstrap-vue';

export function initBootstrapVue(vue) {
  vue.component('b-badge', BBadge);
  vue.component('b-dropdown', BDropdown);
  vue.component('b-dropdown-item', BDropdownItem);
  vue.component('b-link', BLink);
  vue.component('b-alert', BAlert);
  vue.component('b-modal', BModal);
  vue.component('b-button', BButton);
  vue.component('b-navbar', BNavbar);
  vue.component('b-navbar-nav', BNavbarNav);
  vue.component('b-navbar-brand', BNavbarBrand);
  vue.component('b-navbar-toggle', BNavbarToggle);
  vue.component('b-pagination', BPagination);
  vue.component('b-progress', BProgress);
  vue.component('b-progress-bar', BProgressBar);
  vue.component('b-form', BForm);
  vue.component('b-form-input', BFormInput);
  vue.component('b-form-group', BFormGroup);
  vue.component('b-form-checkbox', BFormCheckbox);
  vue.component('b-collapse', BCollapse);
  vue.component('b-nav-item', BNavItem);
  vue.component('b-nav-item-dropdown', BNavItemDropdown);
  vue.component('b-modal', BModal);
  vue.directive('b-modal', VBModal);
  vue.component('b-form-datepicker', BFormDatepicker);
  vue.component('b-input-group', BInputGroup);
  vue.component('b-input-group-prepend', BInputGroupPrepend);
  vue.component('b-avatar', BAvatar);
  vue.component('b-popover', BPopover);
  vue.directive('b-toggle', VBToggle);
  vue.component('b-tabs', BTabs);
  vue.component('b-tab', BTab);
  vue.component('b-button-group', BButtonGroup);
  vue.component('b-input-group-append', BInputGroupAppend);
  vue.component('b-icon-search', BIconSearch);
  vue.component('b-icon-gear', BIconGear);
  vue.component('b-icon-caret-right-fill', BIconCaretRightFill);
  vue.component('b-icon-chevron-down', BIconChevronDown);
  vue.component('b-icon-emoji-wink', BIconEmojiWink);
  vue.component('b-icon-paperclip', BIconPaperclip);
  vue.component('b-icon-reply', BIconReply);
  vue.component('b-icon-trash', BIconTrash);
  vue.component('b-tooltip', BTooltip);
  vue.component('b-icon-forward', BIconForward);
  vue.component('b-icon-x-circle-fill', BIconXCircleFill);
  vue.component('b-form-textarea', BFormTextarea);
  vue.component('b-icon-lock', BIconLock);
  vue.component('b-icon-check2', BIconCheck2);
  vue.component('b-icon-arrow-down-circle', BIconArrowDownCircle);
  vue.component('b-icon-clock', BIconClock);
  vue.component('b-icon-arrow-clock-wise', BIconArrowClockwise);
  vue.component('b-icon-clock-history', BIconClockHistory);
  vue.component('b-dropdown-form', BDropdownForm);
  vue.component('b-toast',BToast)
}
