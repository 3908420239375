import axios from 'axios';

import { ICbVwOrderPercentageStatistic } from '@/shared/model/cb-vw-order-percentage-statistic.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-order-percentage-statistics';

export default class PCbVwOrderPercentageStatisticService {
  public find(id: number): Promise<ICbVwOrderPercentageStatistic> {
    return new Promise<ICbVwOrderPercentageStatistic>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByParamsBuyer(loginType: string, userId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/crm/${loginType}/${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveByParamsFreelancer(loginType: string, userId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/crm/${loginType}/${userId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbVwOrderPercentageStatistic): Promise<ICbVwOrderPercentageStatistic> {
    return new Promise<ICbVwOrderPercentageStatistic>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbVwOrderPercentageStatistic): Promise<ICbVwOrderPercentageStatistic> {
    return new Promise<ICbVwOrderPercentageStatistic>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbVwOrderPercentageStatistic): Promise<ICbVwOrderPercentageStatistic> {
    return new Promise<ICbVwOrderPercentageStatistic>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(loginType: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${loginType}/by-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
