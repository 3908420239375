import { IChtMessageSeen } from '@/shared/model/cht-message-seen.model';
import axios from 'axios';

const baseApiUrl = 'services/btichat/api/cht-message-seens';

export default class ChtMessageSeenService {
  public find(id: number): Promise<IChtMessageSeen> {
    return new Promise<IChtMessageSeen>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IChtMessageSeen): Promise<IChtMessageSeen> {
    return new Promise<IChtMessageSeen>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createForCB(entity: IChtMessageSeen): Promise<IChtMessageSeen> {
    return new Promise<IChtMessageSeen>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cb`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createForLA(entity: IChtMessageSeen, key: string): Promise<IChtMessageSeen> {
    return new Promise<IChtMessageSeen>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/la/${key}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IChtMessageSeen): Promise<IChtMessageSeen> {
    return new Promise<IChtMessageSeen>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IChtMessageSeen): Promise<IChtMessageSeen> {
    return new Promise<IChtMessageSeen>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
