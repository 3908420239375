import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { Module } from 'vuex';
import { ICbVwUser, CbVwUser } from '@/shared/model/cb-vw-user.model';
import { ICbLov } from '@/shared/model/cb-lov.model';

export interface LovStateStorable {
  CONTENT_BANNER_BUYER: ICbLov[];
  CONTENT_BANNER_PARTNER: ICbLov[];
  CONTENT_IMAGE: ICbLov[];
  TIME_ZONE: ICbLov[];
  COUNTRY: ICbLov[];
  PROVINCE: ICbLov[];
  CITY: ICbLov[];
  LANGUAGE_CODE: ICbLov[];
  PHONE_CODE: ICbLov[];
  LANGUAGE: ICbLov[];
  LANGUAGE_LEVEL: ICbLov[];
  MAJOR: ICbLov[];
  JOB: ICbLov[];
}

export const defaultState: LovStateStorable = {
  CONTENT_BANNER_BUYER: [],
  CONTENT_BANNER_PARTNER: [],
  CONTENT_IMAGE: [],
  TIME_ZONE: [],
  COUNTRY: [],
  PROVINCE: [],
  CITY: [],
  LANGUAGE_CODE: [],
  PHONE_CODE: [],
  LANGUAGE: [],
  LANGUAGE_LEVEL: [],
  MAJOR: [],
  JOB: [],
};

export const lovStore: Module<LovStateStorable, any> = {
  state: { ...defaultState },
  getters: {
    CONTENT_BANNER_BUYER: state => state.CONTENT_BANNER_BUYER,
    CONTENT_BANNER_PARTNER: state => state.CONTENT_BANNER_PARTNER,
    CONTENT_IMAGE: state => state.CONTENT_IMAGE,
    TIME_ZONE: state => state.TIME_ZONE,
    COUNTRY: state => state.COUNTRY,
    PROVINCE: state => state.PROVINCE,
    CITY: state => state.CITY,
    LANGUAGE_CODE: state => state.LANGUAGE_CODE,
    PHONE_CODE: state => state.PHONE_CODE,
    LANGUAGE: state => state.LANGUAGE,
    LANGUAGE_LEVEL: state => state.LANGUAGE_LEVEL,
    MAJOR: state => state.MAJOR,
    JOB: state => state.JOB,
  },
  mutations: {
    CONTENT_BANNER_BUYER(state, param) {
      state.CONTENT_BANNER_BUYER = param;
    },
    CONTENT_BANNER_PARTNER(state, param) {
      state.CONTENT_BANNER_PARTNER = param;
    },
    CONTENT_IMAGE(state, param) {
      state.CONTENT_IMAGE = param;
    },
    TIME_ZONE(state, param) {
      state.TIME_ZONE = param;
    },
    COUNTRY(state, param) {
      state.COUNTRY = param;
    },
    PROVINCE(state, param) {
      state.PROVINCE = param;
    },
    CITY(state, param) {
      state.CITY = param;
    },
    LANGUAGE_CODE(state, param) {
      state.LANGUAGE_CODE = param;
    },
    PHONE_CODE(state, param) {
      state.PHONE_CODE = param;
    },
    LANGUAGE(state, param) {
      state.LANGUAGE = param;
    },
    LANGUAGE_LEVEL(state, param) {
      state.LANGUAGE_LEVEL = param;
    },
    MAJOR(state, param) {
      state.MAJOR = param;
    },
    JOB(state, param) {
      state.JOB = param;
    },
  },
};
