import axios from 'axios';
import { ICbUsers } from '@/shared/model/cb-user.model';

const baseApiUrl = 'services/cbuserms/api/users';

export default class PCbUserService {
  public findByEmail(email: string): Promise<ICbUsers> {
    return new Promise<ICbUsers>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-email/${email}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }
}
