import Vue from 'vue';
import axios, { AxiosPromise } from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import AccountService from '@/account/account.service';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import { IKeycloakToken } from '@/shared/model/keycloak-token.model';
import SocketService from '@/admin/socket/socket.service';
import { IChtConversation } from '@/shared/model/cht-conversation.model';
export default class GlobalService {
  constructor(
    private store: Store<any>,
    private accountService: AccountService,
    private router: VueRouter,
    private socketService: SocketService
  ) {}

  public openGlobalDialog(instance: Vue, id: string): void {
    instance.$emit('bv::show::modal', id);
  }

  public closeGlobalDialog(instance: Vue, id: string): void {
    instance.$emit('bv::hide::modal', id);
  }

  public commitChtVwConversation(chtConversation: IChtConversation) {
    this.store.commit('chtConversation', chtConversation);
  }

  public randomBgColor() {
    let color = '#';
    for (let i = 0; i < 3; i++) color += ('0' + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
    return color;
  }

  public async logout() {
    axios.post('api/logout').then(
      response => {
        this.logoutStore();

        if (response && response.data && response.data.logoutUrl) {
          //alert(response.data.logoutUrl)
          //window.location.href = response.data.logoutUrl;
          // https://oid.bti.id/auth/realms/bti/protocol/openid-connect/logout?redirect_uri=null
          if (response.data.logoutUrl.includes('null')) {
            response.data.logoutUrl = response.data.logoutUrl.replace('null', window.location.origin);
            window.location.href = response.data.logoutUrl;
          } else {
            window.location.href = response.data.logoutUrl;
          }
        } else {
          this.router.replace({ path: '/' });
        }
      },
      err => {
        this.logoutStore();

        this.router.replace({ path: '/' });
      }
    );
  }

  public logoutStore() {
    this.store.commit('logout');
    this.store.commit('logoutChat');
    sessionStorage.removeItem('crm-jhi-authenticationToken');
    localStorage.removeItem('crm-jhi-authenticationToken');
    localStorage.removeItem('crm-jhi-id-token');
  }

  public formatDateTime(date: string): Date {
    let find = date.lastIndexOf('.');
    return new Date(date.substring(0, find).replace(/-/g, '/'));
  }

  public login(data: any, that): Promise<any> {
    data.rememberMe = true; // new change  request
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/authenticate/login', data)
        .then(res => {
          this.keepToken(res.data, data.rememberMe);

          resolve(res);
        })
        .catch(err => {
          this.logout();
          if (err.response && err.response.data) {
            // that.$root.$bvToast.toast("The email / password that you've entered is incorrect", {
            //   toaster: 'b-toaster-top-center',
            //   title: 'Error',
            //   variant: 'danger',
            //   solid: true,
            //   autoHideDelay: 5000,
            // });
          }
          reject(err);
        });
    });
  }

  public countdown(second) {
    return dayjs().set('second', second).toDate();
  }

  public isHasRefreshToken() {
    return this.store.getters.keycloakToken;
  }

  public keepToken(data, rememberMe) {
    var keycloakToken: IKeycloakToken = data;
    keycloakToken.rememberMe = rememberMe;
    let jwtAccessToken: any = jwt_decode(keycloakToken.access_token);
    keycloakToken.expires_in = jwtAccessToken.exp * 1000;
    let jwtRefreshToken: any = jwt_decode(keycloakToken.access_token);
    keycloakToken.refresh_expires_in = jwtRefreshToken.exp * 1000;

    this.store.commit('keycloakToken', keycloakToken);

    const jwt = keycloakToken.access_token;
    if (rememberMe) {
      localStorage.setItem('crm-jhi-authenticationToken', jwt);
      sessionStorage.removeItem('crm-jhi-authenticationToken');
    } else {
      sessionStorage.setItem('crm-jhi-authenticationToken', jwt);
      localStorage.removeItem('crm-jhi-authenticationToken');
    }
    localStorage.setItem('crm-jhi-id-token', keycloakToken.id_token);

  }

  public async checkRefreshToken() {
    var keycloakToken: IKeycloakToken = this.store.getters.keycloakToken;

    if (keycloakToken && dayjs(keycloakToken.expires_in).isBefore(dayjs(new Date()))) {
      //expired
      return await new Promise<any>((resolve, reject) => {
        axios
          .post('api/authenticate/refresh', { token: keycloakToken.refresh_token })
          .then(async res => {
            await this.keepToken(res.data, keycloakToken.rememberMe);
            var access_token = res.data.access_token;
            this.socketService.connectCustome();
            resolve(access_token);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    return Promise.resolve(false);
  }

  public handleExpiredRefreshToken() {
    alert('Session is expired');
    this.accountService.logoutStore();
    this.router.replace({ path: '/' });
  }

  public onlyUniqueIds(value, index, self) {
    return self.indexOf(value) === index;
  }
}
