import firebase from 'firebase/app';
import 'firebase/firebase-messaging';
import 'firebase/firebase-analytics';
import 'firebase/firebase-app';
import 'firebase/firestore';

import { Store } from 'vuex';
import AccountService from '@/account/account.service';

let firebaseConfig = {
  apiKey: 'AIzaSyDG02ravLX3Dq73C3P6_xYWBl3oN08W3gU',
  authDomain: 'crm-project-7e610.firebaseapp.com',
  projectId: 'crm-project-7e610',
  storageBucket: 'crm-project-7e610.appspot.com',
  messagingSenderId: '491416373139',
  appId: '1:491416373139:web:ea7e5873cef5a238034a66',
  measurementId: 'G-NZ4L5GECD1',
};

export default class FirebaseConfig {
  public accountService: AccountService;
  public messaging = null;
  public firestore = null;

  constructor(private store: Store<any>, private accountServiceP: AccountService) {
    this.init();
    this.accountService = accountServiceP;
    this.initFirestore();
  }

  public init() {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    let messaging = null;
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
      this.requestPermission();
      this.getToken();
    } else {
      console.log('Browser not support Notification, upgrade your browser');
    }
  }

  public initFirestore() {
    this.firestore = firebase.firestore();
  }

  public requestPermission() {
    // [START messaging_request_permission]
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // TODO(developer): Retrieve a registration token for use with FCM.
        // ...
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
    // [END messaging_request_permission]
  }

  public receiveMessage() {
    this.messaging.onMessage(payload => {
      console.log('show in user see the page. ', payload);
      // ...
    });
  }
  public getToken() {
    this.messaging
      .getToken({ vapidKey: 'BOcgvuSmBvb_OaeOh9AokTOAeGAyH7gf1sAmvf5CFuDpCFwSoOuVUZ6BM3wwIwsWex4uk2avfSiPl91XNy3Lfpk' })
      .then(currentToken => {
        console.log('registeredTokenFCM:' + currentToken);
      });
  }
}
