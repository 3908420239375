import axios from 'axios';

import { ICmUserInfo } from '@/shared/model/cm-user-info.model';

const baseApiUrl = 'services/crmcorems/api/oids/register-user';

export default class PCmCreateAccount {
  // public create(entity: ICmUserInfo): Promise<ICmUserInfo> {
  //   return new Promise<ICmUserInfo>((resolve, reject) => {
  //     axios
  //       .post(`${baseApiUrl}`, entity)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }
  public create(entity: ICmUserInfo, callBack: Function, callbackReject?: Function): Promise<ICmUserInfo> {
    let tmpBlob = null;
    if (entity.cmAttachmentAuth) {
      entity.cmAttachmentAuth.att = '';
      tmpBlob = entity.cmAttachmentAuth.blob;
      delete entity.cmAttachmentAuth.blob;
    }
    return new Promise<ICmUserInfo>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          // resolve(res.data);

          const result: ICmUserInfo = res.data.detail;
          result.cmAttachmentAuth.att = '';
          resolve(this.upload(result, result.uploadLink, entity.cmAttachmentAuth.attContentType, tmpBlob, callBack, callbackReject));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private upload(
    result: ICmUserInfo,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(callbackReject);
        });
    });
  }
}
