import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICmTkt } from '@/shared/model/cm-tkt.model';
import { DisputeAction } from '@/shared/model/enumerations/dispute-action.model';

const baseApiUrl = 'services/crmcorems/api/cm-tkts';

export default class PCmTktService {
  public find(id: number): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, {params: params})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICmTkt): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICmTkt): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICmTkt): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public cmTktdisputeAction(entity: ICmTkt, disputeAction: DisputeAction) :Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/dispute-action/${entity.id}/${disputeAction}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public removeEmailFlag(entity: ICmTkt) :Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/remove-priority/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }


  public cmTktdisputeScoreFromSuperAgent(entity: ICmTkt) :Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/dispute-score/super-agent/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveDisputeActionHistories(cmTKtId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/dispute-action-histories/${cmTKtId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(new Error(err));
        });
    });
  }
}
