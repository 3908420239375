import axios from "axios";
import { ICmVwRegions } from "@/shared/model/cm-vw-regions.model";
import buildPaginationQueryOpts from "@/shared/sort/sorts";

const baseUrl = 'services/crmcorems/api/cm-vw-regions';

export default class PCmVwRegions {
  public retrieve(paginationQuery): Promise<ICmVwRegions[]> {
    return new Promise((resolve, reject) => {
      axios.get(`${baseUrl}?${buildPaginationQueryOpts(paginationQuery)}`).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }
}
