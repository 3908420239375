import Vue from 'vue';

export function initFiltersChat() {
  Vue.filter('chatGetOtherUserPhotoId', (members, currentUserId, vwUsers) => {
    members = (<any>members).replace(/\s/g, '');
    let arr = members.split('||');

    let foundOtherId = arr.find(a => a != currentUserId);

    let ret = 0;

    if (vwUsers && vwUsers.length > 0) {
      let found = vwUsers.find(x => x.id === foundOtherId);
      if (found) {
        ret = found.photoId;
      }
    }
    return ret;
  });

  Vue.filter('chatIsUserOnline', (members, currentUserId, userIdsOnline) => {
    if (!members || !currentUserId || !userIdsOnline) return;

    members = (<any>members).replace(/\s/g, '');
    let arr = members.split('||');
    let foundOtherId = arr.find(a => a != currentUserId);
    return userIdsOnline.find(id => id === foundOtherId) ? true : false;
  });

  Vue.filter('chatIsUserOnlineCb', (realUserId, userIdsOnline) => {
    if (!realUserId  || !userIdsOnline) return;

    return userIdsOnline.find(id => id === realUserId) ? true : false;
  });

  Vue.filter('isUserOnline', (userId, userIdsOnline) => {
    if (!userId || !userIdsOnline) return;

    return userIdsOnline.find(id => id === userId) ? true : false;
  });

  Vue.filter('chatIsStar', (stars, currentUserId) => {
    if (stars && stars.includes(currentUserId)) {
      return true;
    } else {
      return false;
    }
  });

  Vue.filter('chatLanguageLabel', (LANGUAGE_CODE, code) => {
    return LANGUAGE_CODE.find(lov => lov.key === code)?.value || '';
  });
}
