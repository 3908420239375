import axios from 'axios';
import { ICbUserSummary } from '@/shared/model/cb-vw-reg-user-summary.model';
import { ICbFastRegist } from '@/shared/model/cb-fast-regist.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'services/cbregisms/api/cb-vw-reg-user';
const baseApiUrlUpdate = 'services/cbregisms/api/cb-reg-users';
const baseApiEducation = 'services/cbregisms/api/cb-reg-user-educations/by-user-id';
const baseApiLanguage = 'services/cbregisms/api/cb-reg-user-languages/by-user-id';
const baseApiSocialMedia = 'services/cbregisms/api/cb-reg-user-social-medias/by-user-id';

export default class PCbVwRegUserSummaryService {
  public find(id: number): Promise<ICbUserSummary> {
    return new Promise<ICbUserSummary>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-id/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '-summary', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCount(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl + '-summary'}/count`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(cbUserSummary: ICbFastRegist): Promise<ICbFastRegist> {
    return new Promise<ICbFastRegist>((resolve, reject) => {
      axios
        .patch(`${baseApiUrlUpdate}/${cbUserSummary.id}`, cbUserSummary)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retriveUserData(id: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlUpdate}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserEducation(id: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiEducation}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserLanguage(id: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiLanguage}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserSocialMedia(id: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiSocialMedia}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
